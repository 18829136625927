import { TEXT_RESULT, RESULTS_ERROR } from "./types";
import axios from "axios";
import M from "materialize-css";
import translatequiz from '../locales/translatequiz.js';

// Text results
export const textResults = (phone, link) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const msg = translatequiz('textemail.first') + link + translatequiz('textemail.second');

    const sms = {
      contacts: phone,
      message: msg,
    };

    const res = await axios.post("/api/sms/textResults", sms, config);

    dispatch({
      type: TEXT_RESULT,
      payload: res.data,
    });

    M.toast({ html: res.data });

    const modal = document.getElementById("textResultsModal");
    M.Modal.getInstance(modal).close();
  } catch (err) {
    dispatch({
      type: RESULTS_ERROR,
      payload: err,
    });
  }
};
