import React, {useEffect, useState} from 'react';
import M from 'materialize-css';
import {getContactDetails, getVisitorInfo, startQuiz,} from '../../actions/quiz';
import {recordClick} from '../../actions/click';
import {connect} from 'react-redux';
import axios from 'axios';

import PropTypes from 'prop-types';

import translate from '../../locales/translate.js';

const StartQuiz = ({
                       quiz: {user, loading, contact, question},
                       settings: {quiz},
                       startQuiz,
                       getVisitorInfo,
                       getContactDetails,
                       history,
                       match,
                   }) => {
    const [formData, setFormData] = useState({
        parentName: '',
        childName: '',
        contactId: ''
    });
    const contactId = localStorage.getItem('contact');

    useEffect(() => {
        // Set the contact id if it's a parameter in the url
        if ( (!localStorage.getItem('contact') || Object.keys(contact).length <= 0) && match && match.params && match.params.id){
            const loadContactInfo = async () => {
                getContactDetails(match.params.id)
            }
            loadContactInfo()
        }
    }, []);

    // Get user data
    useEffect(() => {
        getVisitorInfo();

        if (!loading) {
            // Check if it should auto-redirect
            if (!loading && contact.name !== undefined) {
                const diff = (new Date() - new Date(contact.date)) / (1000 * 60 * 60 * 24);
                if (diff < 10) {
                    // when updating this (10 days), don't forget to update SendLinks.js
                    setFormData({
                        parentName: !contact.name ? '' : contact.name,
                        childName: !contact.childname ? '' : contact.childname,
                        contactId: contactId || ''
                    });
                }
            } else if (!loading && user && user.parentName) {
                setFormData({
                    parentName: !user.parentName ? '' : user.parentName,
                    childName: !user.childName ? '' : user.childName,
                    contactId: contactId || ''
                });

            }
        } // eslint-disable-next-line
    }, [loading, getVisitorInfo, match, getContactDetails, contact]);

    useEffect(() => {
        if (contact.name && formData.parentName !== '' && Object.keys(question).length <= 0) {

            // If this user already has taken the quiz, send them to the results page 
            const contactId = match.params.id ? match.params.id : contact._id;

            const fetchQuizResults = async () => {
                const res = await axios.get(`/api/results/user/${contactId}`);
                let quizResults = res ? res.data : {};
                
                if(quizResults && Object.keys(quizResults).length > 1)
                {
                    window.location.href = `/results/${quizResults._id}`
                }
                // Immediately start the quiz if the user's information was automatically populated
                // else 
                // {
                //     startQuiz(formData, history);
                // }
                return quizResults;
            }
            fetchQuizResults()

        } // eslint-disable-next-line
    }, [formData]);

    // Call M function to format fields when formData is filled from localStorage
    useEffect(() => {
        M.updateTextFields();
    }, [formData]);

    const {parentName, childName} = formData;

    const onChange = (e) =>
        setFormData({...formData, [e.target.name]: e.target.value});

    // Handle the quiz language selection
    const [selectedLang, setSelectedLang] = useState(localStorage.getItem('quizlang') || 'en');
    const onChangeLanguage = (e) => {
        setSelectedLang(e.target.value);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        startQuiz(formData, history);
        localStorage.setItem('quizlang', selectedLang);
    };


    return (
        <div className='row' onMouseDown={(e) => recordClick(e)}>
            <div className='col s12'>
                <h3 className='mb-4'>{quiz.starttitle}</h3>{' '}
                <p className='mb-3'>
                    {translate('quiz.header')}
                </p>
                <form onSubmit={(e) => onSubmit(e)}>
                    <div className='input-field pb-3'>
                        <input
                            name='parentName'
                            id='parentName'
                            type='text'
                            className='validate'
                            value={parentName}
                            onChange={(e) => onChange(e)}
                            required
                        />
                        <label htmlFor='parentName'>{translate('quiz.yourname')}</label>
                        <span
                            className='helper-text'
                            data-error='Please type your name'
                            data-success=''
                        />
                    </div>
                    <div className='input-field pb-3'>
                        <input
                            name='childName'
                            id='childName'
                            type='text'
                            className='validate'
                            value={childName}
                            onChange={(e) => onChange(e)}
                        />
                        <label htmlFor='childName'>{translate('quiz.childsname')}</label>
                    </div>
                    <div className="center">
                        <p>{translate('quiz.language')}</p>
                        <label>
                            <input
                                name="quizlang"
                                type="radio"
                                value="en"
                                checked={selectedLang === 'en'}
                                onChange={(e) => onChangeLanguage(e)}
                            />
                            <span style={{padding: '0 25px'}}>English</span>
                        </label>
                        <label>
                            <input
                                name="quizlang"
                                type="radio"
                                value="es"
                                checked={selectedLang === 'es'}
                                onChange={(e) => onChangeLanguage(e)}
                            />
                            <span style={{padding: '0 25px'}}>Español</span>
                        </label>

                        <button
                            className='btn waves-effect waves-light'
                            type='submit'
                            name='action'
                        >
                            {translate('quiz.getstarted')}
                            <i className='material-icons right'>send</i>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

StartQuiz.propTypes = {
    quiz: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    getVisitorInfo: PropTypes.func.isRequired,
    getContactDetails: PropTypes.func.isRequired,
    startQuiz: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    quiz: state.quiz,
    settings: state.dashboard.siteContents,
});

export default connect(mapStateToProps, {
    getVisitorInfo,
    getContactDetails,
    startQuiz,
})(StartQuiz);
