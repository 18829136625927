import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const QuizRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !user || !user.parentName ? (
        <Redirect to='/quiz' />
      ) : (
        <Component {...props} />
      )
    }
  />
);

QuizRoute.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.quiz.user
});

export default connect(mapStateToProps)(QuizRoute);
