import React, { useEffect, Fragment, useState } from 'react';
import {
  getVisitorInfo,
  getMainConcerns,
  saveOrderedResults,
  restartQuiz,
} from '../../actions/quiz';
import { recordClick } from '../../actions/click';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import M from 'materialize-css';
import PropTypes from 'prop-types';
import ConcernsList from '../concerns/ConcernsList';
import TextResultsModal from '../modals/TextResultsModal';
import Spinner from '../layout/Spinner';
import ReactGA from 'react-ga4';

import translatequiz from '../../locales/translatequiz.js';

const Results = ({
  quiz: { user, loading, concerns },
  settings: { quiz },
  getVisitorInfo,
  getMainConcerns,
  saveOrderedResults,
  restartQuiz,
  history,
  match,
}) => {
  const [orderedConcerns, setOrderedConcerns] = useState(concerns);
  const [submitting, setSubmitting] = useState(false);

  const confirmationMessage = "You are about to leave the page without submitting your results. Would you like to continue?";
  const blockingFunction = (location, action) => {
    if(!location.pathname.includes('results') && !location.pathname.includes('quiz'))
      return confirmationMessage;
    
  };

  // Set up the blocking function
  useEffect(() => {
    const unblock = history.block(blockingFunction);
    return () => {
      unblock();
    };
  }, [history]);

  useEffect(() => {
    if (!orderedConcerns.length) {
      setOrderedConcerns(concerns);
    }
  }, [concerns, setOrderedConcerns]);

  // Get user data
  useEffect(() => {
    getVisitorInfo();
    getMainConcerns(match.params.id);
  }, [getVisitorInfo, getMainConcerns, match.params.id]);

  useEffect(() => {
    const elems = document.querySelectorAll('.fixed-action-btn');
    M.FloatingActionButton.init(elems, {
      hoverEnabled: false,
    });

    const tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips, {
      position: 'left',
    });

    // Show a confirmation alert before the user tries to close the page or leave the site without saving results (requires user to have some interaction with the page first)
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return "";
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };


  }, []);

  const printResults = (e) => {
    e.preventDefault();
    window.print();
  };

  const submitRanking = (e) => {
    setSubmitting(true);
    saveOrderedResults(match.params.id, orderedConcerns, history);

    // Set user label for analytics
    let parentTag = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).parentName + " " : "No Name ";
    if(localStorage.getItem('redcap'))
    {
      parentTag += localStorage.getItem('redcap')
    }

    // Keep Google Anlytics record if the user rearranged
    if(JSON.stringify(orderedConcerns) != JSON.stringify(concerns))
    {
      ReactGA.event({
        category: 'quiz',
        action: 'Reranked Concerns',
        label: parentTag,
      });
    }
  };

  const looks = [
    'looks_one',
    'looks_two',
    'looks_3',
    'not_interested',
    'not_interested',
    'not_interested',
    'not_interested',
    'not_interested',
    'not_interested',
    'not_interested',
    'not_interested',
    'not_interested',
    'not_interested',
    'not_interested',
    'not_interested',
    'not_interested',
    'not_interested',
  ];

  // Sortable
  const SortableItem = sortableElement(({ value, i }) => (
    <li
      style={{
        listStyle: 'none',
      }}
    >
      <div className='collapsible-header'>
        <i className='material-icons'>{`${looks[i]}`}</i> {value}
      </div>
    </li>
  ));
  const SortableContainer = sortableContainer(({ children }) => {
    return <ul className='collapsible popout'>{children}</ul>;
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newItems = arrayMove(orderedConcerns, oldIndex, newIndex);
    setOrderedConcerns(newItems);
  };

  return (
    <Fragment>
      <div className='row' onMouseDown={(e) => recordClick(e)}>
        <div className='col s12'>
          <h3 className='mb-5'>{translatequiz('rank.rank')}</h3>
          <p className='flow-text'>
            {translatequiz('rank.selected')}
          </p>
          <div style={{width: '100%', padding: 15, background: '#fff3cd', border: 'solid 5px #ffeeba', color: '#856404', borderRadius: 3, marginBottom: 30}}>
            <i className='material-icons left'>warning</i>{translatequiz('rank.makesure')}
          </div>
          {(loading || !concerns.length) && <Spinner />}

          {!loading && concerns && orderedConcerns && (
            <SortableContainer onSortEnd={onSortEnd}>
              {orderedConcerns.map((concern, index) => (
                <SortableItem
                  key={`item-${concern._id}`}
                  index={index}
                  i={index}
                  value={(localStorage.getItem('quizlang') == 'es') ? concern.name_es : concern.name}
                />
              ))}
            </SortableContainer>
          )}

          <p className='center'>
            <button
              onClick={(e) => submitRanking(e)}
              className='primary-color waves-effect waves-light btn-large'
            >
              <i className='material-icons left'>check</i>{' '}
              {!submitting && <span>{translatequiz('rank.seeresults')}</span>}
              {submitting && <span>Saving...</span>}
            </button>
          </p>
        </div>
      </div>
    </Fragment>
  );
};

Results.propTypes = {
  quiz: PropTypes.object.isRequired,
  getVisitorInfo: PropTypes.func.isRequired,
  getMainConcerns: PropTypes.func.isRequired,
  saveOrderedResults: PropTypes.func.isRequired,
  restartQuiz: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  quiz: state.quiz,
  settings: state.dashboard.siteContents,
});

export default connect(mapStateToProps, {
  getVisitorInfo,
  getMainConcerns,
  saveOrderedResults,
  restartQuiz,
})(Results);
