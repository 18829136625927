import React, { useEffect, Fragment } from 'react';
import M from 'materialize-css';
import { getConcernNames } from '../../actions/concerns';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import ReactQuill, { Quill } from 'react-quill';
import Delta from 'quill-delta';
import ImageResize from 'quill-image-resize-module-react';
import { ImageDrop } from 'quill-image-drop-module';
import 'react-quill/dist/quill.snow.css';

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDrop', ImageDrop);

const CreateQuestionForm = ({
  formData,
  setFormData,
  getConcernNames,
  concerns: { concerns, loading },
  type = 'create',
}) => {
  // Call M function to format fields
  useEffect(() => {
    getConcernNames();

    let elems = document.getElementById('concern');
    M.FormSelect.init(elems);
  }, [getConcernNames, loading]);

  const { text, text_es, concern, description, labelA, labelB, labelC, labelD, labelE, labelA_es, labelB_es, labelC_es, labelD_es, labelE_es } =
    formData;

  let reactQuillRef;

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onChangeCustom = (value, name) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const uploadFunction = (e) => {
    let selectedFile = e.target.files[0];

    let form = new FormData();
    form.append('file', selectedFile);
    form.append('name', selectedFile.name);

    //upload image to server
    axios
      .post('/api/s3/upload', form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((r) => {
        console.log('success', r);

        let range = reactQuillRef.getEditor().getSelection(true);

        reactQuillRef.getEditor().updateContents(
          new Delta().retain(range.index).delete(range.length).insert({
            image: r.data.Data[0].Location,
          }),
          'user'
        );
      })
      .catch((e) => {
        console.log('error', e);
      });
  };

  return (
    <Fragment>
      <div className='input-field'>
        <input
          id='text'
          name='text'
          type='text'
          className='validate'
          value={text}
          onChange={(e) => onChange(e)}
          required
        />
        <label htmlFor='text'>Question * English</label>
      </div>
      <div className='input-field'>
        <input
          id='text_es'
          name='text_es'
          type='text'
          className='validate'
          value={text_es}
          onChange={(e) => onChange(e)}
          required
        />
        <label htmlFor='text_es'>Question * Espanol</label>
      </div>
      <div className='input-field'>
        <select
          name='concern'
          id='concern'
          onChange={(e) => onChange(e)}
          value={concern}
          required
        >
          <option value='' disabled>
            Choose your option
          </option>
          <option value=''>- No concern -</option>
          {!loading &&
            concerns.map((concern) => (
              <option key={concern._id} value={concern._id}>
                {concern.name}
              </option>
            ))}
        </select>
        <label htmlFor='concern'>Concern *</label>
      </div>
      <div className='input-field mt-4 pt-3'>
        {(type === 'edit' && !loading && text) ||
        (type === 'create' && !loading) ? (
          <Fragment>
            <input
              type='file'
              onChange={(e) => uploadFunction(e)}
              id='file'
              hidden
            />
            <ReactQuill
              ref={(el) => {
                reactQuillRef = el;
              }}
              value={description}
              onChange={(value) => onChangeCustom(value, 'description')}
              onFocus={() => {
                var toolbar = reactQuillRef.getEditor().getModule('toolbar');
                toolbar.addHandler('image', function () {
                  document.getElementById('file').click();
                });
              }}
              modules={{
                clipboard: {
                  matchVisual: false,
                },
                toolbar: {
                  container: [
                    [{ header: [1, 2, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [
                      { list: 'ordered' },
                      { list: 'bullet' },
                      { indent: '-1' },
                      { indent: '+1' },
                      { align: [] },
                    ],
                    ['link', 'image', 'video'],
                  ],
                },
                imageResize: {
                  parchment: Quill.import('parchment'),
                  modules: ['Resize', 'DisplaySize'],
                },
                imageDrop: true,
              }}
            />
          </Fragment>
        ) : (
          ''
        )}
        <label className='active'>Question Content</label>
      </div>

      <div className='input-field'>
        <input
          id='labelA'
          name='labelA'
          type='text'
          className='validate'
          placeholder='Strongly Agree'
          value={labelA}
          onChange={(e) => onChange(e)}
        />
        <label htmlFor='text'>Answer A (5 points)</label>
      </div>
      <div className='input-field'>
        <input
          id='labelB'
          name='labelB'
          type='text'
          className='validate'
          placeholder='Agree'
          value={labelB}
          onChange={(e) => onChange(e)}
        />
        <label htmlFor='text'>Answer B (4 points)</label>
      </div>
      <div className='input-field'>
        <input
          id='labelC'
          name='labelC'
          type='text'
          className='validate'
          placeholder='Neither agree nor disagree'
          value={labelC}
          onChange={(e) => onChange(e)}
        />
        <label htmlFor='text'>Answer C (3 points)</label>
      </div>
      <div className='input-field'>
        <input
          id='labelD'
          name='labelD'
          type='text'
          className='validate'
          placeholder='Disagree'
          value={labelD}
          onChange={(e) => onChange(e)}
        />
        <label htmlFor='text'>Answer D (2 points)</label>
      </div>
      <div className='input-field'>
        <input
          id='labelE'
          name='labelE'
          type='text'
          className='validate'
          placeholder='Strongly Disagree'
          value={labelE}
          onChange={(e) => onChange(e)}
        />
        <label htmlFor='text'>Answer E (1 point)</label>
      </div>
      <div className='input-field'>
        <input
          id='labelA_es'
          name='labelA_es'
          type='text'
          className='validate'
          placeholder='Strongly Agree'
          value={labelA_es}
          onChange={(e) => onChange(e)}
        />
        <label htmlFor='text'>Answer A (5 points) Spanish</label>
      </div>
      <div className='input-field'>
        <input
          id='labelB_es'
          name='labelB_es'
          type='text'
          className='validate'
          placeholder='Agree'
          value={labelB_es}
          onChange={(e) => onChange(e)}
        />
        <label htmlFor='text'>Answer B (4 points) Spanish</label>
      </div>
      <div className='input-field'>
        <input
          id='labelC_es'
          name='labelC_es'
          type='text'
          className='validate'
          placeholder='Neither agree nor disagree'
          value={labelC_es}
          onChange={(e) => onChange(e)}
        />
        <label htmlFor='text'>Answer C (3 points) Spanish</label>
      </div>
      <div className='input-field'>
        <input
          id='labelD_es'
          name='labelD_es'
          type='text'
          className='validate'
          placeholder='Disagree'
          value={labelD_es}
          onChange={(e) => onChange(e)}
        />
        <label htmlFor='text'>Answer D (2 points) Spanish</label>
      </div>
      <div className='input-field'>
        <input
          id='labelE_es'
          name='labelE_es'
          type='text'
          className='validate'
          placeholder='Strongly Disagree'
          value={labelE_es}
          onChange={(e) => onChange(e)}
        />
        <label htmlFor='text'>Answer E (1 point) Spanish</label>
      </div>

    </Fragment>
  );
};

CreateQuestionForm.propTypes = {
  getConcernNames: PropTypes.func.isRequired,
  concerns: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  concerns: state.concerns,
});

export default connect(mapStateToProps, { getConcernNames })(
  CreateQuestionForm
);
