import React, { useEffect, useState } from 'react';
import { sendSMS } from '../../actions/dashboard';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import M from 'materialize-css';

const SMSModal = ({ sendSMS, contacts, clearSelected }) => {
  useEffect(() => {
    const modals = document.querySelectorAll('.modal');
    M.Modal.init(modals);
  }, []);

  const [formData, setFormData] = useState({
    message:
      localStorage.getItem('smsMessage') !== null
        ? localStorage.getItem('smsMessage')
        : ''
  });

  const { message } = formData;

  const onChange = e => {
    if (e.target.name === 'message') {
      localStorage.setItem('smsMessage', e.target.value);
    }

    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div id='smsModal' className='modal center'>
      <div className='modal-content'>
        <h4>Send SMS</h4>
        <p>
          Create or edit the message below to send it to the selected contacts:
        </p>
        <form
          onSubmit={e => {
            e.preventDefault();
            sendSMS(contacts, formData, clearSelected);
          }}
        >
          <div className='card-content lighten-4'>
            <div className='input-field mt-5'>
              <textarea
                height= '100px'
                id='message'
                name='message'
                placeholder='Type the message to be sent here'
                className='materialize-textarea'
                onChange={e => onChange(e)}
                value={message}
                required
              />
              <label htmlFor='message'>Message *</label>
              <span className='helper-text'>
                The link will be added to the end of the message
              </span>
            </div>
          </div>
          <div className='card-action'>
            <button type='submit' className='btn waves-effect'>
              <i className='material-icons left'>sms</i> Send SMS Now
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

SMSModal.propTypes = {
  sendSMS: PropTypes.func.isRequired
};

export default connect(
  null,
  {
    sendSMS
  }
)(SMSModal);
