import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { adminLoadFeedbacks } from '../../actions/dashboard';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import Moment from 'react-moment';

const ListFeedbacks = ({ feedbacks, adminLoadFeedbacks }) => {
  useEffect(() => {
    adminLoadFeedbacks();
  }, [adminLoadFeedbacks]);

  useEffect(() => {
    const tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips, {
      enterDelay: 500
    });
  }, [feedbacks]);

  return (
    <Fragment>
      <div className='row'>
        <div className='col s12'>
          <h3>
            <Link
              to='/dashboard'
              className='tooltipped'
              data-tooltip='Back to Dashboard'
            >
              <i className='material-icons small'>arrow_back</i>
            </Link>{' '}
            Feedbacks
          </h3>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='card'>
            <div className='card-content'>
              <p>See the feedbacks given by the site users.</p>
            </div>
            <div className='card-content grey lighten-4'>
              <ul className='collection with-header'>
                {feedbacks.map(feedback => {
                  const { _id, date, score } = feedback;
                  return (
                    <li key={_id} className='collection-item'>
                      <Moment format='LLL'>{date}</Moment>
                      <div className='secondary-content yellow-text'>
                        {[...Array(5)].map((_, i) => (
                          <i key={i} className='material-icons'>
                            {i < score ? 'star' : 'star_border'}
                          </i>
                        ))}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ListFeedbacks.propTypes = {
  adminLoadFeedbacks: PropTypes.func.isRequired,
  feedbacks: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  feedbacks: state.dashboard.feedbacks
});

export default connect(
  mapStateToProps,
  {
    adminLoadFeedbacks
  }
)(ListFeedbacks);
