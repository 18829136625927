import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import M from 'materialize-css';
import Spinner from '../layout/Spinner';
import ConcernFeedbackModal from '../modals/ConcernFeedbackModal';
import ReactGA from 'react-ga4';
import { recordClick } from '../../actions/click';
import { getConcerns } from '../../actions/concerns';
import PropTypes from 'prop-types';

import translatequiz from '../../locales/translatequiz.js';

const Concerns = ({ 
  items, 
  withIcon = false, 
  getConcerns,
  concerns: { concerns },
}) => {

  const [concernTimer, setConcernTimer] = useState(Date.now());
  const [timedConcernName, setTimedConcernName] = useState("");
  const [parentId, setParentId] = useState("");
  const [finalItems, setFinalItems] = useState([]);

  useEffect(() => {
    // Set user label for analytics
    let parentTag = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).parentName + " " : "No Name ";
    if(localStorage.getItem('redcap'))
    {
      parentTag += localStorage.getItem('redcap')
    }
    
    setParentId(parentTag);
  }, []);

  useEffect(() => {
    let elems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems);
  }, [finalItems]);

  // Get concerns
  useEffect(() => {
    getConcerns();
  }, [getConcerns]);

  // Set the final order of the retrieved concerns based on the order of which we have in the items 
  useEffect(() => {
    if(items.length > 0 && concerns.length > 0)
    {
      const reordered = items.map(({ _id }) =>
        concerns.find(item => item._id === _id)
      );
      setFinalItems(reordered)
    }
  }, [concerns, items]);

  const timeConcern = function (state, concernName) {

    // If there is already an open concern somewhere else 
    if(timedConcernName != "")
    {
      const endTime = new Date();

      const calculatedSeconds = Math.abs(endTime.getTime() - concernTimer.getTime()) / 1000;

      

      ReactGA.event({
        category: timedConcernName,
        action: "View Duration",
        label: parentId,
        concern_title: timedConcernName, 
        value: calculatedSeconds,
      })

    }


    // If this concern wasn't already open
    if(state == "active")
    {
      setTimedConcernName(concernName);
      setConcernTimer(new Date());     
    }
    else 
    {
      setTimedConcernName("");
    }

  };

  return finalItems.length <= 0 ? (
    <Spinner />
  ) : (
    <ul className='collapsible popout' onMouseDown={(e) => recordClick(e)}>
      {finalItems.map((concern, key) => {
        const looks = ['one', 'two', '3'];
        return (
          <li
            key={concern._id}
            style={{
              display: !withIcon || key < 3 ? 'block' : 'none',
            }}
          >
            <div className='collapsible-header'
              onClick={(e) => {
                var state = e.target.parentElement.className;
                ReactGA.event({
                  category: concern.name,
                  action: state=="active" ? "Open Concern" : "Close Concern",
                  label: concern.name,
                  concern_title: concern.name
                })
                timeConcern(state, concern.name);
              }}
            >
              {withIcon && (
                <i className='material-icons'>{`looks_${looks[key]}`}</i>
              )}
              {(localStorage.getItem('quizlang') == 'es') ? concern.name_es : concern.name}
            </div>
            <div className='collapsible-body quill-content'>
              <div
                className='quill-content'
                dangerouslySetInnerHTML={{ __html: (localStorage.getItem('quizlang') == 'es') ? concern.content_es : concern.content }}
              />
              {concern.suggestedquestions.length > 0 && (
                <div className='mb-4'>
                  <hr className='mt-4' />
                  <h5>{translatequiz('quiz.suggested')}</h5>
                  <ul>
                    {(localStorage.getItem('quizlang') == 'es') ?
                    (concern.suggestedquestions_es.map((question, k) => (
                      <li key={k}>{question}</li>
                    ))) : 
                    (concern.suggestedquestions.map((question, k) => (
                      <li key={k}>{question}</li>
                    )))
                    }
                  </ul>
                </div>
              )}
              {concern.externallinks.length > 0 && (
                <div className='mb-4'>
                  <hr className='mt-4' />
                  <h5>{translatequiz('quiz.additional')}</h5>
                  <ul>
                    {concern.externallinks.map((link, k) => (
                      <li key={k}>
                        <a
                          href={link.url}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='external-resource'
                          onClick={() =>
                            {
                              ReactGA.event({
                                category: concern.name,
                                action: 'Concern resource clicked',
                                label: parentId,
                                concern_title: concern.name
                              })

                              ReactGA.event({
                                category: concern.name + " - " + link.name,
                                action: 'Concern resource clicked by user',
                                label: parentId,
                                concern_title: concern.name
                              })
                            }
                          }
                        >
                          {link.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <Fragment>
                <hr className='mt-4' />
                <div className='center mt-4' 
                   >
                  {concern.videolink !== '' && (
                    <a
                      href={concern.videolink}
                      target='_blank'
                      className='btn btn-primary video-link'
                      rel='noopener noreferrer'
                      onClick={() => 
                        ReactGA.event({
                          category: concern.name,
                          action: "open video",
                          label: parentId,
                          concern_title: concern.name
                        })
                      }
                    >
                      <i className='material-icons left'>videocam</i>
                      {translatequiz('quiz.video')}
                      
                    </a>
                    
                  )}
                  <br/>
                  <br/>
                  <Link
                    to={`/concerns/${concern._id}`}
                    target='_blank'
                    className='btn primary-color ml-3'
                    rel='noopener noreferrer'
                    onClick={() =>
                      ReactGA.event({
                        category: concern.name,
                        action: 'share with kids button',
                        label: parentId,
                        concern_title: concern.name
                      })
                    }
                  >
                    <i className='material-icons left'>escalator_warning</i>
                    {translatequiz('quiz.sharewithkids')}
                  </Link><br/>
                  <a
                    className='btn red ml-3 mr-3 mt-3 modal-trigger' href='#concernFeedbackModal'
                    onClick={(e) => {
                      document.querySelector('#current-concern').value = concern.name;
                      ReactGA.event({
                        category: concern.name,
                        action: 'feedback button clicked',
                        label: parentId,
                        concern_title: concern.name,
                      })
                    }
                    }
                  >
                    <i className='material-icons left'>feedback</i>
                    {translatequiz('quiz.feedback')}
                  </a>
                </div>
              </Fragment>
            </div>
          </li>
        );
      })}
      <ConcernFeedbackModal/>
    </ul>
  );
};

Concerns.propTypes = {
  getConcerns: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  quiz: state.quiz,
  concerns: state.concerns,
});

export default connect(mapStateToProps, {
  getConcerns,
})(Concerns);