import React, { useEffect, useState } from 'react';
import { textResults } from '../../actions/results';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import ReactGA from 'react-ga4';
import translatequiz from '../../locales/translatequiz.js';

const FeedbackModal = ({ 
    textResults
 }) => {
  useEffect(() => {
    const modals = document.querySelectorAll('.modal');
    M.Modal.init(modals);
  }, []);

  const [phoneNumber, setPhoneNumber] = useState('+1');

  const onSubmit = (e) => {
    e.preventDefault();

    // Set user label for analytics
    let parentTag = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).parentName + " " : "No Name ";
    if(localStorage.getItem('redcap'))
    {
      parentTag += localStorage.getItem('redcap')
    }
    
    ReactGA.event({
      category: 'quiz results',
      action: 'Share Results via SMS',
      label: parentTag,
    });

    textResults(phoneNumber, window.location.href + '/share');
  };

  return (
    <div id='textResultsModal' className='modal center'>
      <form onSubmit={(e) => onSubmit(e)} className='pt-3'>
        <div className='modal-content'>
          <h4>{translatequiz('quiz.text')}</h4>
          <p>{translatequiz('textemail.pleaseinsert')}</p>
          <div className='input-field'>
            <input
              id='phone'
              type='text'
              className='validate'
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              minLength='12'
              required
            />
            <label htmlFor='phone'>{translatequiz('textemail.mobile')}</label>
            <span
              className='helper-text'
              data-error='Please check the number above. It needs to be in international format: +1 000 111 2222'
              data-success=''
            >
              {translatequiz('textemail.international')}
            </span>
          </div>
        </div>
        <div className='modal-footer'>
          <button type='submit' className='waves-effect btn primary-color'>
          {translatequiz('textemail.results')}
          </button>
        </div>
      </form>
    </div>
  );
};

FeedbackModal.propTypes = {
  textResults: PropTypes.func.isRequired,
};

export default connect(null, {
  textResults,
})(FeedbackModal);
