import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  adminLoadQuestions,
  adminReorderQuestions,
  adminToggleQuestionStatus,
} from '../../actions/dashboard';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import arrayMove from 'array-move';
import classnames from 'classnames';

const ListQuestions = ({
  questions,
  adminLoadQuestions,
  adminReorderQuestions,
  adminToggleQuestionStatus,
}) => {
  const [reordered, setReordered] = useState(false);

  useEffect(() => {
    adminLoadQuestions();
  }, [adminLoadQuestions]);

  useEffect(() => {
    const tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips, {
      enterDelay: 500,
    });
  }, [questions]);

  const updateQuestionOrder = (question, direction) => {
    // question: index, direction: -1 moves up, +1 moves down
    arrayMove.mutate(questions, question, question + direction);
    setReordered(true);
  };

  const sendQuestionsToReorder = () => {
    const qs = questions.map((q) => {
      return q._id;
    });
    adminReorderQuestions(qs, setReordered);
  };

  let i = 0;

  return (
    <Fragment>
      <div className='row'>
        <div className='col s12'>
          <h3>
            <Link
              to='/dashboard'
              className='tooltipped'
              data-tooltip='Back to Dashboard'
            >
              <i className='material-icons small'>arrow_back</i>
            </Link>{' '}
            Manage Questions
          </h3>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='card'>
            <div className='card-content'>
              <p>
                Manage the questions displayed in the survey and the concerns
                they represent. <br />
                Use the add button at the bottom right to add a question.
              </p>
            </div>
            <div className='card-content grey lighten-4'>
              <ul className='collection with-header'>
                {questions.map((question, key) => {
                  i = question.active ? i + 1 : i;
                  return (
                    <li
                      key={question._id}
                      className={classnames('collection-item', {
                        disabled: !question.active,
                      })}
                    >
                      <div className='question-actions'>
                        <span className='truncate question-text'>
                          <strong>{question.active ? i : '-'}.</strong>{' '}
                          {question.text}
                        </span>
                        <small className='blue-text'>
                          {question.concern && question.concern.name}&nbsp;{' '}
                        </small>
                        <div
                          className='switch secondary-content tooltipped'
                          data-tooltip='Toggle visibility'
                        >
                          <label>
                            <input
                              type='checkbox'
                              checked={question.active}
                              onChange={(e) =>
                                adminToggleQuestionStatus(question._id)
                              }
                            />
                            <span className='lever' />
                          </label>
                        </div>
                        <Link
                          to='#!'
                          onClick={(e) => {
                            if (!e.target.classList.contains('disabled'))
                              updateQuestionOrder(key, -1);
                          }}
                          className='secondary-content ml-3 tooltipped'
                          data-tooltip='Move up'
                        >
                          <i
                            className={classnames('material-icons', {
                              disabled: key === 0,
                            })}
                          >
                            arrow_upward
                          </i>
                        </Link>
                        <Link
                          to='#!'
                          onClick={(e) => {
                            if (!e.target.classList.contains('disabled'))
                              updateQuestionOrder(key, 1);
                          }}
                          className='secondary-content ml-3 tooltipped'
                          data-tooltip='Move down'
                        >
                          <i
                            className={classnames('material-icons', {
                              disabled: key + 1 === questions.length,
                            })}
                          >
                            arrow_downward
                          </i>
                        </Link>
                        <Link
                          to={`/dashboard/questions/edit/${question._id}`}
                          className='secondary-content ml-3 tooltipped'
                          data-tooltip='Edit question'
                        >
                          <i className='material-icons'>edit</i>
                        </Link>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className='card-action'>
              <button
                type='button'
                className='btn waves-effect'
                onClick={(e) => sendQuestionsToReorder()}
                disabled={!reordered}
              >
                <i className='material-icons left'>save</i> Save questions order
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='fixed-action-btn'>
        <Link
          to='/dashboard/questions/new'
          className='waves-effect btn-floating btn-large red tooltipped z-depth-3'
          data-tooltip='New question'
          data-position='left'
        >
          <i className='large material-icons'>add</i>
        </Link>
      </div>
    </Fragment>
  );
};

ListQuestions.propTypes = {
  adminLoadQuestions: PropTypes.func.isRequired,
  adminReorderQuestions: PropTypes.func.isRequired,
  adminToggleQuestionStatus: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  questions: state.dashboard.questions,
});

export default connect(mapStateToProps, {
  adminLoadQuestions,
  adminReorderQuestions,
  adminToggleQuestionStatus,
})(ListQuestions);
