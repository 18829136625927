import { GET_QUESTION, QUESTION_ERROR, GET_QUESTIONS } from './types';
import axios from 'axios';

// Get Question
export const getQuestion = id => async dispatch => {
  try {
    const res = await axios.get(`/api/questions/${id}`);
    dispatch({
      type: GET_QUESTION,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: QUESTION_ERROR,
      payload: err
    });
  }
};

// Get Questions
export const getQuestions = () => async dispatch => {
  try {
    const res = await axios.get(`/api/questions`);
    dispatch({
      type: GET_QUESTIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: QUESTION_ERROR,
      payload: err
    });
  }
};
