import ReactGA from 'react-ga4';

let classifyClick = e => {
  let click = '';
  click = (e.target.id !== undefined && e.target.id !== '') ? `${e.target.id}` : click;
  click = (e.target.className !== undefined && e.target.className !== '') ? (click !== '' ? `${click} ${e.target.className}` : `${e.target.className}`) : click;
  click = click !== '' ? `${click} ${e.target.nodeName}` : `${e.target.nodeName}`;
  click = (e.target.href !== undefined && e.target.href !== '') ? `${click} ${e.target.href}` : click;
  click = (e.target.src !== undefined && e.target.src !== '') ? `${click} ${e.target.src}` : click;
  return click;
}

export const recordClick = (e) => {
    ReactGA.event({
      category: "interaction",
      action: "click",
      label: classifyClick(e)
    })
  }