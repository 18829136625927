import axios from 'axios';
import FileDownload from 'js-file-download';
import M from 'materialize-css';

import {
  ADMIN_LOAD_CONTENTS,
  ADMIN_ERROR,
  ADMIN_SAVE_CONTENTS,
  ADMIN_LOAD_QUESTIONS,
  ADMIN_SEND_SMS,
  ADMIN_SAVE_QUESTION,
  ADMIN_REORDER_QUESTIONS,
  ADMIN_TOGGLE_QUESTION_STATUS,
  CLEAR_QUESTION,
  ADMIN_LOAD_CONCERNS,
  ADMIN_REORDER_CONCERNS,
  ADMIN_TOGGLE_CONCERN_STATUS,
  CLEAR_CONCERN,
  ADMIN_SAVE_CONCERN,
  ADMIN_LOAD_FEEDBACKS,
  ADMIN_CREATE_CONTACT,
  ADMIN_LOAD_CONTACTS,
  ADMIN_DELETE_CONTACTS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  CREATE_USER_FAIL,
  ADMIN_LOAD_USERS,
  ADMIN_LOAD_USER,
  ADMIN_CLEAR_USER,
  ADMIN_DELETE_USER,
  ADMIN_SAVING_CONCERN,
} from './types';

export const adminLoadContents = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/settings');

    dispatch({
      type: ADMIN_LOAD_CONTENTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_ERROR,
      payload: err,
    });
  }
};

export const adminSaveContents = (settings, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.put('/api/settings', settings, config);

    dispatch({
      type: ADMIN_SAVE_CONTENTS,
      payload: res.data,
    });

    M.toast({ html: 'Settings saved succesfully!' });
    history.push('/dashboard');
  } catch (err) {
    console.log(err);
    dispatch({
      type: ADMIN_ERROR,
      payload: err,
    });
    M.toast({
      html: 'There was an error while saving your settings. Please try again later.',
    });
  }
};

export const adminLoadQuestions = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/questions/all');

    dispatch({
      type: ADMIN_LOAD_QUESTIONS,
      payload: res.data,
    });
    dispatch({
      type: CLEAR_QUESTION,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_ERROR,
      payload: err,
    });
  }
};

export const adminSaveQuestion = (question, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let res;

    if (question.concern == '') {
      question.concern = null;
    }

    if (question._id) {
      res = await axios.put(`/api/questions/${question._id}`, question, config);
    } else {
      res = await axios.post('/api/questions', question, config);
    }

    dispatch({
      type: ADMIN_SAVE_QUESTION,
      payload: res.data,
    });

    dispatch({
      type: CLEAR_QUESTION,
    });

    M.toast({ html: 'Question saved successfully!' });
    history.push('/dashboard/questions');
  } catch (err) {
    console.log(err);
    dispatch({
      type: ADMIN_ERROR,
      payload: err,
    });
    M.toast({
      html: 'There was an error while saving your settings. Please try again later.',
    });
  }
};

export const adminReorderQuestions =
  (questions, setReordered) => async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const res = await axios.post('/api/questions/reorder', questions, config);

      dispatch({
        type: ADMIN_REORDER_QUESTIONS,
        payload: res.data,
      });

      setReordered(false);
      M.toast({ html: 'Questions reordered successfully!' });
    } catch (err) {
      console.log(err);
      dispatch({
        type: ADMIN_ERROR,
        payload: err,
      });
      M.toast({
        html: 'There was an error while reordering the questions. Please try again later.',
      });
    }
  };

export const adminToggleQuestionStatus = (id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.put(`/api/questions/toggle/${id}`, config);

    dispatch({
      type: ADMIN_TOGGLE_QUESTION_STATUS,
      payload: res.data,
    });

    M.toast({ html: 'Question status saved!' });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ADMIN_ERROR,
      payload: err,
    });
  }
};

export const adminLoadConcerns = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/concerns/all');

    dispatch({
      type: ADMIN_LOAD_CONCERNS,
      payload: res.data,
    });
    dispatch({
      type: CLEAR_CONCERN,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_ERROR,
      payload: err,
    });
  }
};

export const adminSaveConcern = (concern, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let res;
    dispatch({
      type: ADMIN_SAVING_CONCERN,
    });

    if (concern._id) {
      res = await axios.put(`/api/concerns/${concern._id}`, concern, config);
    } else {
      res = await axios.post('/api/concerns', concern, config);
    }

    dispatch({
      type: ADMIN_SAVE_CONCERN,
      payload: res.data,
    });

    dispatch({
      type: CLEAR_CONCERN,
    });

    M.toast({ html: 'Concern saved successfully!' });
    history.push('/dashboard/concerns');
  } catch (err) {
    console.log(err);
    dispatch({
      type: ADMIN_ERROR,
      payload: err,
    });
    M.toast({
      html: 'There was an error while saving your settings. Please try again later.',
    });
  }
};

export const adminReorderConcerns =
  (concerns, setReordered) => async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const res = await axios.post('/api/concerns/reorder', concerns, config);

      dispatch({
        type: ADMIN_REORDER_CONCERNS,
        payload: res.data,
      });

      setReordered(false);
      M.toast({ html: 'Concerns priorities reordered successfully!' });
    } catch (err) {
      console.log(err);
      dispatch({
        type: ADMIN_ERROR,
        payload: err,
      });
      M.toast({
        html: 'There was an error while reordering the concerns. Please try again later.',
      });
    }
  };

export const adminToggleConcernStatus = (id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.put(`/api/concerns/toggle/${id}`, config);

    dispatch({
      type: ADMIN_TOGGLE_CONCERN_STATUS,
      payload: res.data,
    });

    M.toast({ html: 'Concern status saved!' });
  } catch (err) {
    console.log(err);
    dispatch({
      type: ADMIN_ERROR,
      payload: err,
    });
  }
};

export const sendSMS =
  (contacts, formData, clearSelected) => async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const sms = {
        contacts,
        message: formData.message,
        url: `${window.location.protocol}//${window.location.host}/quiz`,
      };

      const res = await axios.post('/api/sms/send', sms, config);

      dispatch({
        type: ADMIN_SEND_SMS,
        payload: res.data,
      });

      M.toast({ html: res.data });
      clearSelected();

      const modal = document.getElementById('smsModal');
      M.Modal.getInstance(modal).close();
    } catch (err) {
      dispatch({
        type: ADMIN_ERROR,
        payload: err,
      });
    }
  };

export const adminLoadFeedbacks = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/feedbacks');

    dispatch({
      type: ADMIN_LOAD_FEEDBACKS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_ERROR,
      payload: err,
    });
  }
};

export const adminExportResults = (setGeneratingFile) => async (dispatch) => {
  try {
    const res = await axios.get('/api/results/csv');
    FileDownload(res.data, 'results.csv');
    setGeneratingFile(false);
  } catch (err) {
    dispatch({
      type: ADMIN_ERROR,
      payload: err,
    });
  }
};

export const adminExportBrokenResults = (setBrokenGeneratingFile) => async (dispatch) => {
  try {
    const res = await axios.get('/api/results/brokencsv');
    FileDownload(res.data, 'problem_results.csv');
    setBrokenGeneratingFile(false);
  } catch (err) {
    dispatch({
      type: ADMIN_ERROR,
      payload: err,
    });
  }
};

export const adminExportFeedbacks =
  (setGeneratingFeedbacksFile, type) => async (dispatch) => {
    try {
      const res = await axios.get('/api/feedbacks/csv', {params: {type}});
      FileDownload(res.data, type + '-feedbacks.csv');
      setGeneratingFeedbacksFile(false);
    } catch (err) {
      dispatch({
        type: ADMIN_ERROR,
        payload: err,
      });
    }
  };

export const adminCreateContact =
  (contact, setNewContactInfo) => async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const res = await axios.post('/api/contacts', contact, config);

      dispatch({
        type: ADMIN_CREATE_CONTACT,
        payload: res.data,
      });

      M.toast({ html: 'New contact added!' });

      M.updateTextFields();

      setNewContactInfo({
        name: '',
        childname: '',
        phone: '',
        redcapid: '',
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: ADMIN_ERROR,
        payload: err,
      });
      M.toast({
        html: 'There was an error while creating this contact. Please try again later.',
      });
    }
  };

export const adminLoadContacts = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/contacts');

    dispatch({
      type: ADMIN_LOAD_CONTACTS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_ERROR,
      payload: err,
    });
  }
};

export const adminDeleteContacts =
  (contacts, clearSelected) => async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const conts = contacts.map((contact) => contact.id);
      const res = await axios.delete(
        '/api/contacts',
        { data: { conts } },
        config
      );

      dispatch({
        type: ADMIN_DELETE_CONTACTS,
        payload: res.data,
      });

      clearSelected();
    } catch (err) {
      dispatch({
        type: ADMIN_ERROR,
        payload: err,
      });
    }
  };

// Register User
export const adminCreateUser =
  ({ _id, name, email, password, history }) =>
  async (dispatch) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      let res;
      let body;

      if (password !== '') {
        body = JSON.stringify({ _id, name, email, password });
      } else {
        body = JSON.stringify({ _id, name, email });
      }

      if (_id) {
        res = await axios.put(`/api/users/${_id}`, body, config);
      } else {
        res = await axios.post('/api/users', body, config);
      }

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });

      M.toast({ html: 'User saved successfully!' });
      history.push('/dashboard/users');
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error) => M.toast({ html: error.msg }));
      }

      dispatch({
        type: CREATE_USER_FAIL,
      });
    }
  };

export const adminLoadUsers = () => async (dispatch) => {
  try {
    const res = await axios.get('/api/users');

    dispatch({
      type: ADMIN_LOAD_USERS,
      payload: res.data,
    });
    dispatch({
      type: ADMIN_CLEAR_USER,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_ERROR,
      payload: err,
    });
  }
};

export const adminGetUser = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/users/${id}`);

    dispatch({
      type: ADMIN_LOAD_USER,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_ERROR,
      payload: err,
    });
  }
};

// Delete User
export const adminDeleteUser = (id) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/users/${id}`);

    dispatch({
      type: ADMIN_DELETE_USER,
      payload: res.data,
    });

    M.toast({ html: 'User deleted!' });
  } catch (err) {
    dispatch({
      type: ADMIN_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};
