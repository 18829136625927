import { SEND_FEEDBACK } from '../actions/types';
const initialState = {};

export default function(state = initialState, action) {
  // eslint-disable-next-line
  const { type, payload } = action;

  switch (type) {
    case SEND_FEEDBACK:
    default:
      return state;
  }
}
