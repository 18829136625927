import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import MainOptionsForm from './MainOptionsForm';
import HomePageOptionsForm from './HomePageOptionsForm';
import QuizOptionsForm from './QuizOptionsForm';
import { adminSaveContents } from '../../actions/dashboard';
import { connect } from 'react-redux';

const EditContents = ({
  dashboard: { siteContents, loading },
  adminSaveContents,
  history
}) => {
  useEffect(() => {
    const tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips);

    const tabs = document.querySelectorAll('.tabs');
    M.Tabs.init(tabs);
  }, []);

  const [formData, setFormData] = useState({
    main: {
      sitename: '',
      copyright: ''
    },
    homePage: {
      calltoaction_en: '',
      calltoaction_es: '',
      headline1_en: '',
      headline1_es: '',
      headline2_en: '',
      headline2_es: '',
      icon1_en: '',
      icon1_es: '',
      icon2_en: '',
      icon2_es: '',
      icon3_en: '',
      icon3_es: '',
      maintitle_en: '',
      maintitle_es: '',
      subtitle_en: '',
      subtitle_es: '',
      text1_en: '',
      text1_es: '',
      title1_en: '',
      title1_es: '',
      title2_en: '',
      title2_es: '',
      title3_en: '',
      title3_es: ''
    },
    quiz: {
      starttitle_en: '',
      starttitle_es: '',
      thankyoutitle_en: '',
      thankyoutitle_es: '',
      top3text_en: '',
      top3text_es: '',
      topparentconcernstext_en: '',
      topparentconcernstext_es: '',
      topparentconcernstitle_en: '',
      topparentconcernstitle_es: ''
    },
  });

  useEffect(() => {
    if (!loading) {
      setFormData({
        ...formData,
        ...siteContents
      });
    }
    // eslint-disable-next-line
  }, [loading]);

  return (
    <Fragment>
      <div className='row'>
        <div className='col s12'>
          <h3>
            <Link
              to='/dashboard'
              className='tooltipped'
              data-tooltip='Back to Dashboard'
            >
              <i className='material-icons small'>arrow_back</i>
            </Link>{' '}
            Page Contents
          </h3>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='card'>
            <div className='card-content'>
              <p>
                Here you can edit all the static texts from across the
                application, including the landing page.
              </p>
            </div>
            <div className='card-tabs'>
              <ul className='tabs tabs-fixed-width'>
                <li className='tab'>
                  <a href='#main'>Main Options</a>
                </li>
                <li className='tab'>
                  <a href='#home'>Home Page</a>
                </li>
                <li className='tab'>
                  <a href='#quiz'>Quiz</a>
                </li>
              </ul>
            </div>
            <div className='card-content grey lighten-4'>
              <div id='main'>
                <MainOptionsForm
                  formData={formData}
                  setFormData={setFormData}
                />
              </div>
              <div id='home'>
                <HomePageOptionsForm
                  formData={formData}
                  setFormData={setFormData}
                  loading={loading}
                />
              </div>
              <div id='quiz'>
                <QuizOptionsForm
                  formData={formData}
                  setFormData={setFormData}
                />
              </div>
            </div>
            <div className='card-action'>
              <button
                type='button'
                className='btn waves-effect'
                onClick={e => adminSaveContents(formData, history)}
              >
                <i className='material-icons left'>save</i> Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

EditContents.propTypes = {
  dashboard: PropTypes.object.isRequired,
  adminSaveContents: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  dashboard: state.dashboard
});

export default connect(
  mapStateToProps,
  {
    adminSaveContents
  }
)(EditContents);
