import React, { Fragment } from 'react';

const CreateUserForm = ({
  formData,
  setFormData,
  loading,
  type = 'create'
}) => {
  const { name, email, password, password2 } = formData;

  const onChange = e =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

  return (
    <Fragment>
      <div className='input-field'>
        <input
          type='text'
          name='name'
          value={name}
          onChange={e => onChange(e)}
          required
        />
        <label htmlFor='name'>Name *</label>
      </div>
      <div className='input-field'>
        <input
          type='email'
          name='email'
          value={email}
          onChange={e => onChange(e)}
          required
        />
        <label htmlFor='email'>Email Address *</label>
      </div>
      <div className='input-field'>
        <input
          type='password'
          name='password'
          value={password}
          onChange={e => onChange(e)}
          required={type === 'create'}
        />
        <label htmlFor='password'>
          Password {type === 'create' ? '*' : ''}
        </label>
      </div>
      <div className='input-field'>
        <input
          type='password'
          name='password2'
          value={password2}
          onChange={e => onChange(e)}
          required={type === 'create'}
        />
        <label htmlFor='password2'>
          Confirm Password {type === 'create' ? '*' : ''}
        </label>
      </div>
    </Fragment>
  );
};

export default CreateUserForm;
