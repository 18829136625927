import React, { Fragment, useEffect, useState } from 'react';
import M from 'materialize-css';
import { Link } from 'react-router-dom';
import CreateUserForm from './CreateUserForm';
import { adminCreateUser } from '../../actions/dashboard';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const CreateUser = ({ adminCreateUser, history }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    password2: ''
  });

  const { name, email, password, password2 } = formData;

  useEffect(() => {
    const tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips);
  }, []);

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== password2) {
      M.toast({ html: 'Passwords do not match!' });
    } else {
      adminCreateUser({ _id: null, name, email, password, history });
    }
  };

  return (
    <Fragment>
      <div className='row'>
        <div className='col s12'>
          <h3>
            <Link
              to='/dashboard/users'
              className='tooltipped'
              data-tooltip='Back to Users'
            >
              <i className='material-icons small'>arrow_back</i>
            </Link>{' '}
            Create User
          </h3>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='card'>
            <form onSubmit={e => onSubmit(e)}>
              <div className='card-content grey lighten-4'>
                <CreateUserForm formData={formData} setFormData={setFormData} />
              </div>
              <div className='card-action'>
                <button type='submit' className='btn waves-effect'>
                  <i className='material-icons left'>person_add</i> Create user
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

CreateUser.propTypes = {
  adminCreateUser: PropTypes.func.isRequired
};

export default connect(
  null,
  {
    adminCreateUser
  }
)(CreateUser);
