import {
  SAVE_VISITOR_INFO,
  GET_VISITOR_INFO,
  QUIZ_ERROR,
  QUESTION_LOADED,
  QUESTION_ANSWERED,
  QUIZ_RESTARTED,
  LOAD_RESULTS,
  LOAD_CONTACT,
  CLEAR_CONTACT,
} from './types';
import axios from 'axios';
import ReactGA from "react-ga4";

// Get visitor info
export const getVisitorInfo = () => (dispatch) => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));

    dispatch({
      type: GET_VISITOR_INFO,
      payload: user,
    });
  } catch (error) {
    dispatch({
      type: QUIZ_ERROR,
      payload: {
        msg: "Can't load your data. Please try again later.",
        status: 500,
      },
    });
  }
};

// Save visitor info and start quiz
export const startQuiz = (formData, history) => (dispatch) => {
  try {
    localStorage.setItem('user', JSON.stringify(formData));

    dispatch({
      type: SAVE_VISITOR_INFO,
      payload: formData,
    });
    history.push('/question/1');
  } catch (err) {
    dispatch({
      type: QUIZ_ERROR,
      payload: {
        msg: "Can't save your data. Please try again later.",
        status: 500,
      },
    });
  }
};

// Get question by number
export const getQuestionByNumber = (number) => async (dispatch) => {
  try {
    let question;
    let answers = JSON.parse(localStorage.getItem('answers')) || [];
    let answer;

    if (answers && answers.length) {
      answer = answers.filter((ans) => ans.number === number);
    }

    if (answer && answer.length) {
      // user already answered, get data from localStorage
      question = answer[0];
      console.log('Loaded question from localStorage');
    } else {
      // first time answering, get question from db
      const res = await axios.get(`/api/questions/pos/${number}`);

      question = res.data;

      console.log('Loaded question from server');
    }

    dispatch({
      type: QUESTION_LOADED,
      payload: {
        question,
        answers,
      },
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: QUIZ_ERROR,
      payload: {
        msg: "Can't load the question. Please try again later.",
        status: 500,
      },
    });
  }
};

// Answer question
export const answerQuestion = (question, history, user) => async (dispatch) => {
  try {
    let answers = JSON.parse(localStorage.getItem('answers')) || [];

    answers = answers
      .filter((ans) => ans._id !== question._id)
      .concat(question);

    localStorage.setItem('answers', JSON.stringify(answers));

    dispatch({
      type: QUESTION_ANSWERED,
      payload: answers,
    });

    const next = parseFloat(question.number) + 1;
    
    if (question.isLast) {
      // Save and send to results
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const answersObj = answers.map((ans) => {
        let concernId = null;
        if (ans.concern) {
          concernId = ans.concern._id;
        }
        return {
          question: ans.text,
          concern: concernId,
          answer: ans.option,
        };
      });

      const contact = localStorage.getItem('contact');
      const language = localStorage.getItem('quizlang');

      const userObj = {
        parentname: ' ',
        childname: ' ',
        language: language,
        answers: answersObj,
      };

      if (contact !== 'null') {
        userObj.contact = contact;
      }
      
      const res = await axios.post(`/api/results`, userObj, config);
      
      localStorage.setItem('userResultsId', res.data);
      window.location.href = `/rank/${res.data}`;
    } else {
      history.push(`/question/${next}`);
    }
  } catch (error) {
    dispatch({
      type: QUIZ_ERROR,
      payload: {
        msg: "Can't answer the question. Please try again later.",
        status: 500,
      },
    });
  }
};

// Restart quiz
export const restartQuiz = (history) => (dispatch) => {
  try {
    const contactId = localStorage.getItem('contact');
    localStorage.clear();
    localStorage.setItem('contact', contactId);
    history.push('/quiz');
    dispatch({
      type: QUIZ_RESTARTED,
    });
  } catch (error) {
    dispatch({
      type: QUIZ_ERROR,
      payload: {
        msg: "Can't do that now. Please try again later.",
        status: 500,
      },
    });
  }
};

// Save Ordered Results
export const saveOrderedResults =
  (id, orderedConcerns, history) => async (dispatch) => {
    try {
      // Get answers obj
      const ansRes = await axios.get(`/api/results/${id}`);
      let answers = ansRes.data;

      // Remove the content section of the concerns so we're not sending huge queries
      let orderedConcernsIds = orderedConcerns
      if (typeof orderedConcerns !== 'undefined')
      {
        orderedConcernsIds = orderedConcerns.map(obj => {
          const { content, content_es, childrencontent, childrencontent_es, videolink, externallinks, suggestedquestions, suggestedquestions_es, ...rest } = obj;
          return rest;
        });
      }

      answers['orderedConcerns'] = orderedConcernsIds;

      // Save and send to results
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      let userObj = answers;
      userObj.contact = userObj.userid;
      const res = await axios.post(`/api/results`, userObj, config);

      localStorage.setItem('userResultsId', res.data);
      history.push(`/results/${res.data}`);
    } catch (error) {
      dispatch({
        type: QUIZ_ERROR,
        payload: {
          msg: "Can't set results. Please try again later.",
          status: 500,
        },
      });
    }

    // Delete old entry from results
    const resDel = await axios.delete(`/api/results/${id}`);
  };

// Calculate results
export const calculateResults = (id) => async (dispatch) => {
  try {
    dispatch({
      type: LOAD_RESULTS,
      payload: [],
    });
    // Get answers
    const ansRes = await axios.get(`/api/results/${id}`);
    const concerns = ansRes.data.orderedConcerns;
    if (
      window.location.hash == '#share' ||
      window.location.hash == '#!#share'
    ) {
      concerns.length = 3;
    }
    dispatch({
      type: LOAD_RESULTS,
      payload: concerns,
    });
  } catch (error) {
    dispatch({
      type: QUIZ_ERROR,
      payload: {
        msg: "Can't load your data. Please try again later.",
        status: 500,
      },
    });
  }
};

// Get concerns for ranking
export const getMainConcerns = (id) => async (dispatch) => {
  try {
    // 1 - Get all concerns in order of priority
    const res = await axios.get(`/api/concerns`);
    let concerns = res.data;
    concerns.map((conc) => (conc.count = 0));

    // 2 - Get answers
    const ansRes = await axios.get(`/api/results/${id}`);
    const answers = ansRes.data.answers;

    // 3 - Map through answers and count how many points each concern has received
    answers.forEach((ans) => {
      concerns.forEach((conc) => {
        if (ans.concern && conc._id === ans.concern._id)
          conc.count = conc.count + ans.answer;
      });
    });

    // 4 - Remove concerns with score < 4
    concerns = concerns.sort((a, b) => b.count - a.count);
    let selected = [];
    concerns.forEach((concern) => {
      if (concern.count >= 4) {
        selected.push(concern);
      }
    });

    // 5 - In case there are less than 3 concerns, let's fill with other concerns, which are already ordered by priority
    // Changing this in Feb 28. 2021 to show all concerns according to this request: i think we miscommunicated on the “rank your concerns” as this should them ranked, but should show all of the concerns not just their top 3
    //if (selected.length < 3) {
    concerns.forEach((concern) => {
      if (concern.count < 4 /* && selected.length < 3*/) {
        selected.push(concern);
      }
    });
    //}

    dispatch({
      type: LOAD_RESULTS,
      payload: selected,
    });
  } catch (error) {
    dispatch({
      type: QUIZ_ERROR,
      payload: {
        msg: "Can't load your data. Please try again later.",
        status: 500,
      },
    });
  }
};

// Get contact details and mark it as inactive
export const getContactDetails = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/contacts/${id}`);
    let contact = res.data;
    if (contact){
      localStorage.setItem('contact', contact._id);
      localStorage.setItem('redcap', contact.redcapid);
      ReactGA.set({ userId: contact.redcapid });
      dispatch({
        type: LOAD_CONTACT,
        payload: contact,
      });
    }

  } catch (error) {
    dispatch({
      type: QUIZ_ERROR,
      payload: {
        msg: "Can't load your data. Please try again later.",
        status: 500,
      },
    });
  }
};

// Clear contact details
export const clearContactDetails = () => (dispatch) => {
  try {
    console.log('cleared');
    dispatch({
      type: CLEAR_CONTACT,
    });
  } catch (error) {
    dispatch({
      type: QUIZ_ERROR,
      payload: {
        msg: "Can't load your data. Please try again later.",
        status: 500,
      },
    });
  }
};
