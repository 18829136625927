import React, { useEffect } from 'react';
import M from 'materialize-css';

const QuizOptionsForm = ({ formData, setFormData }) => {
  const { quiz } = formData;

  // Call M function to format fields
  useEffect(() => {
    M.updateTextFields();
  }, [formData]);

  const {
    starttitle_en,
    thankyoutitle_en,
    top3text_en,
    topparentconcernstitle_en,
    topparentconcernstext_en,
    starttitle_es,
    thankyoutitle_es,
    top3text_es,
    topparentconcernstitle_es,
    topparentconcernstext_es
  } = quiz;

  const onChange = e =>
    setFormData({
      ...formData,
      quiz: {
        ...quiz,
        [e.target.name]: e.target.value
      }
    });

  return (
    <form>
      <div className='input-field'>
        <input
          id='starttitle_en'
          name='starttitle_en'
          type='text'
          className='validate'
          value={starttitle_en}
          onChange={e => onChange(e)}
          required
        />
        <label htmlFor='starttitle'>Start Quiz Title * English</label>
      </div>
      <div className='input-field'>
        <input
          id='starttitle_es'
          name='starttitle_es'
          type='text'
          className='validate'
          value={starttitle_es}
          onChange={e => onChange(e)}
          required
        />
        <label htmlFor='starttitle'>Start Quiz Title * Español</label>
      </div>

      <div className='input-field'>
        <input
          id='thankyoutitle_en'
          name='thankyoutitle_en'
          type='text'
          className='validate'
          value={thankyoutitle_en}
          onChange={e => onChange(e)}
          required
        />
        <label htmlFor='thankyoutitle'>Top 3 Page Title * English</label>
      </div>
      <div className='input-field'>
        <input
          id='thankyoutitle_es'
          name='thankyoutitle_es'
          type='text'
          className='validate'
          value={thankyoutitle_es}
          onChange={e => onChange(e)}
          required
        />
        <label htmlFor='thankyoutitle'>Top 3 Page Title * Español</label>
      </div>

      <div className='input-field'>
        <input
          id='top3text_en'
          name='top3text_en'
          type='text'
          className='validate'
          value={top3text_en}
          onChange={e => onChange(e)}
        />
        <label htmlFor='top3text'>Top 3 Page Text English</label>
      </div>
      <div className='input-field'>
        <input
          id='top3text_es'
          name='top3text_es'
          type='text'
          className='validate'
          value={top3text_es}
          onChange={e => onChange(e)}
        />
        <label htmlFor='top3text'>Top 3 Page Text Español</label>
      </div>

      <div className='input-field'>
        <input
          id='topparentconcernstitle_en'
          name='topparentconcernstitle_en'
          type='text'
          className='validate'
          value={topparentconcernstitle_en}
          onChange={e => onChange(e)}
          required
        />
        <label htmlFor='topparentconcernstitle'>
          Top Parent Concerns Title * English
        </label>
      </div>
      <div className='input-field'>
        <input
          id='topparentconcernstitle_es'
          name='topparentconcernstitle_es'
          type='text'
          className='validate'
          value={topparentconcernstitle_es}
          onChange={e => onChange(e)}
          required
        />
        <label htmlFor='topparentconcernstitle'>
          Top Parent Concerns Title * Español
        </label>
      </div>

      <div className='input-field'>
        <input
          id='topparentconcernstext_en'
          name='topparentconcernstext_en'
          type='text'
          className='validate'
          value={topparentconcernstext_en}
          onChange={e => onChange(e)}
        />
        <label htmlFor='topparentconcernstext'>Top Parent Concerns Text English</label>
      </div>
      <div className='input-field'>
        <input
          id='topparentconcernstext_es'
          name='topparentconcernstext_es'
          type='text'
          className='validate'
          value={topparentconcernstext_es}
          onChange={e => onChange(e)}
        />
        <label htmlFor='topparentconcernstext'>Top Parent Concerns Text Español</label>
      </div>

    </form>
  );
};

export default QuizOptionsForm;
