import React, { Fragment } from 'react';

import dempsey from '../../img/dempsey.jpg';
import erves from '../../img/erves.jpg';
import hull from '../../img/hull.jpg';
import willkins from '../../img/willkins.jpg';

import translate from '../../locales/translate.js';

const AboutUs = () => {

    return (
        <Fragment>
            <div className="row">
                <div className="col s12">
                    <h3 className='mt-0'> {translate('navbar.aboutus')}</h3>
                </div>
                <div className="col l3">
                    <img src={erves} alt='' style={{ maxWidth: '100%' }} />
                    <p className='large'><strong>Jennifer Cunningham Erves, PhD, MPH, MAEd, MS, CHES</strong></p>
                    <p className=''>{translate('aboutus.erves')}</p>
                </div>
                <div className="col l3">
                    <img src={hull} alt='' style={{ maxWidth: '100%' }} />
                    <p className='large'><strong>Pamela Hull, PhD</strong></p>
                    <p className=''>{translate('aboutus.hull')}</p>
                </div>
                <div className="col l3">
                    <img src={dempsey} alt='' style={{ maxWidth: '100%' }} />
                    <p className='large'><strong>Amanda Dempsey, MD, PhD, MPH</strong></p>
                    <p className=''>{translate('aboutus.dempsey')}</p>
                </div>
                <div className="col l3">
                    <img src={willkins} alt='' style={{ maxWidth: '100%' }} />
                    <p className='large'><strong>Consuelo Wilkins, MD, MSCI</strong></p>
                    <p className=''>{translate('aboutus.wilkins')}</p>
                </div>
            </div>
        </Fragment>
    );
};

export default AboutUs;
