import {
  GET_CONCERNS,
  CONCERNS_ERROR,
  CLEAR_CONCERN,
  GET_CONCERN,
  ADMIN_SAVING_CONCERN
} from '../actions/types';

const initialState = {
  concerns: [],
  concern: {},
  error: {},
  loading: true,
  saving: false
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_CONCERN:
      return {
        ...state,
        concern: payload,
        loading: false,
        saving: false
      };
    case GET_CONCERNS:
      return {
        ...state,
        concerns: payload,
        loading: false,
        saving: false
      };
    case CLEAR_CONCERN:
      return {
        ...state,
        concern: {},
        loading: true,
        saving: false
      };
    case CONCERNS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
        saving: false
      };
    case ADMIN_SAVING_CONCERN:
      return {
        ...state,
        saving: true
      };
    default:
      return state;
  }
}
