import React, { Fragment, useState } from 'react';
import Recaptcha from 'react-recaptcha'
import axios from "axios";
import translate from '../../locales/translate.js';

const RECAPTCHA_KEY = "AIzaSyAfu8IkcbAGgNsyzNjvldAzdcbl5TDCS4Y";

const ContactUs = () => {
    const [name, setName] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const submit = async () => {
        setSubmitting(true);
        try{
            await axios.post("http://localhost:5000/api/contact", {name, email, message});
            alert("Your submission has been sent.")
        }catch(err){
            console.log(err);
            alert("Something went wrong, please try again later.")
        }
        setSubmitting(false);
        setName("");
        setEmail("");
        setMessage("");
    }
    return (
        <Fragment>
            <Recaptcha sitekey={RECAPTCHA_KEY} />
            <div>
                <h3 className='mt-0'> {translate('navbar.contactus')}</h3>
                <p><label>{translate('contactus.name')}:</label><input type="text" name="name" onChange={e => setName(e.target.value)} value={name} /></p>
                <p><label>{translate('contactus.email')}:</label><input type="text" name="email" onChange={e => setEmail(e.target.value)} value={email} /></p>
                <p><label>{translate('contactus.message')}:</label><textarea cols={50} rows={50} name="message" onChange={e => setMessage(e.target.value)} value={message} /></p>
                <p>{submitting ? <button className="primary-color waves-effect waves-light btn-large" disabled={true}>{translate('submit')}</button> :
                    <button className="primary-color waves-effect waves-light btn-large" onClick={() => submit()}>{translate('submit')}</button> }
                </p>
            </div>
        </Fragment>
    );
};

export default ContactUs;
