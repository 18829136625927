import React, { Fragment, useEffect, useState } from 'react';
import M from 'materialize-css';
import { Link } from 'react-router-dom';
import CreateUserForm from './CreateUserForm';
import { adminCreateUser, adminGetUser } from '../../actions/dashboard';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const EditUser = ({
  adminGetUser,
  adminCreateUser,
  match,
  history,
  dashboard: { user, loading }
}) => {
  const [formData, setFormData] = useState({
    _id: '',
    name: '',
    email: '',
    password: '',
    password2: ''
  });

  const { _id, name, email, password, password2 } = formData;

  // Tooltips
  useEffect(() => {
    const tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips);
  }, []);

  // Load concern
  useEffect(() => {
    adminGetUser(match.params.id);

    setFormData({
      name: loading || !user.name ? '' : user.name,
      email: loading || !user.email ? '' : user.email,
      _id: loading || !user._id ? '' : user._id
    });

    // eslint-disable-next-line
  }, [match.params.id, adminGetUser, loading]);

  // Update Materialize fields
  useEffect(() => {
    M.updateTextFields();
  }, [user]);

  const onSubmit = async e => {
    e.preventDefault();
    if (password !== '' && password !== password2) {
      M.toast({ html: 'Passwords do not match!' });
    } else {
      adminCreateUser({ _id, name, email, password, password2, history });
    }
  };

  return (
    <Fragment>
      <div className='row'>
        <div className='col s12'>
          <h3>
            <Link
              to='/dashboard/users'
              className='tooltipped'
              data-tooltip='Back to Users'
            >
              <i className='material-icons small'>arrow_back</i>
            </Link>{' '}
            Edit User
          </h3>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='card'>
            <div className='card-content grey lighten-4'>
              <CreateUserForm
                formData={formData}
                setFormData={setFormData}
                loading={loading}
                type='edit'
              />
            </div>
            <div className='card-action'>
              <button
                type='button'
                className='btn waves-effect'
                onClick={e => onSubmit(e)}
              >
                <i className='material-icons left'>save</i> Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

EditUser.propTypes = {
  dashboard: PropTypes.object.isRequired,
  adminGetUser: PropTypes.func.isRequired,
  adminCreateUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  dashboard: state.dashboard
});

export default connect(
  mapStateToProps,
  {
    adminGetUser,
    adminCreateUser
  }
)(EditUser);
