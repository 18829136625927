import React, { useEffect, useState } from 'react';
import { sendFeedback } from '../../actions/feedback';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import translatequiz from '../../locales/translatequiz.js';

const ConcernFeedbackModal = ({ sendFeedback }) => {
  useEffect(() => {
    const modals = document.querySelectorAll('.modal');
    M.Modal.init(modals, {
      onOpenStart: e => setFormData({...clearFormData})
    });
    const textArea = document.querySelectorAll('.materialize-textarea');
    M.CharacterCounter.init(textArea);
  }, []);

  const [formData, setFormData] = useState({
    type: 'concern',
    concern: '',
    meetexpectations: false,
    additionalinformation: "",
    howhelpful: 0,
  });

  const clearFormData = formData;

  const { meetexpectations, additionalinformation } = formData;

  const submitFeedback = e => {
    formData['concern'] = document.querySelector('#current-concern').value;
    sendFeedback(formData);
  }

  const onChange = e =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  
  const onClick = (item, val) => {
    setFormData({
      ...formData,
      [item]: val
    });
  }

  const onCbox = e =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked
    });

  return (
    <div id='concernFeedbackModal' className='modal center'>
      <div className='modal-content'>
        <input type="hidden" name="concern" id="current-concern" />
        <h4>{translatequiz('feedbacks.giveus')}</h4>
        <p>{translatequiz('feedbacks.meetexpectations')}</p>
        <div className="switch ">
          <label>
            {translatequiz('feedback.no')}
            <input type="checkbox" name="meetexpectations" checked={meetexpectations} onChange={e => onCbox(e)} />
            <span className="lever"></span>
            {translatequiz('feedback.yes')}
          </label>
        </div>
        <br/>
        <p>{translatequiz('feedbacks.additionalinformation')}</p>
        <div className="row">
          <div className="input-field col s8 offset-s2">
            <textarea id="additionalinformation" name="additionalinformation" value={additionalinformation} onChange={e => onChange(e)} className="materialize-textarea" data-length="120"></textarea>
            <label htmlFor="additionalinformation"></label>
          </div>
        </div>
        <p>{translatequiz('feedbacks.howhelpful')}</p>
        <div>
          <span className='rate-us'>
            <i onClick={e => onClick('howhelpful', 1)} className='material-icons medium'>
              <span className='mouse-off' style={{ display: formData['howhelpful'] >= 1? 'none': ''}}>star_border</span>
              <span className='mouse-on' style={{ display: formData['howhelpful'] >= 1? 'block': ''}}>star</span>
            </i>
            <i onClick={e => onClick('howhelpful', 2)} className='material-icons medium'>
              <span className='mouse-off' style={{ display: formData['howhelpful'] >= 2? 'none': ''}}>star_border</span>
              <span className='mouse-on' style={{ display: formData['howhelpful'] >= 2? 'block': ''}}>star</span>
            </i>
            <i onClick={e => onClick('howhelpful', 3)} className='material-icons medium'>
              <span className='mouse-off' style={{ display: formData['howhelpful'] >= 3? 'none': ''}}>star_border</span>
              <span className='mouse-on' style={{ display: formData['howhelpful'] >= 3? 'block': ''}}>star</span>
            </i>
            <i onClick={e => onClick('howhelpful', 4)} className='material-icons medium'>
              <span className='mouse-off' style={{ display: formData['howhelpful'] >= 4? 'none': ''}}>star_border</span>
              <span className='mouse-on' style={{ display: formData['howhelpful'] >= 4? 'block': ''}}>star</span>
            </i>
            <i onClick={e => onClick('howhelpful', 5)} className='material-icons medium'>
              <span className='mouse-off' style={{ display: formData['howhelpful'] >= 5? 'none': ''}}>star_border</span>
              <span className='mouse-on' style={{ display: formData['howhelpful'] >= 5? 'block': ''}}>star</span>
            </i>
          </span>
        </div>
        <br/>
        <button className="btn waves-effect waves-light" type="button" onClick={e => submitFeedback(e)}>{translatequiz('submit')}
          <i className="material-icons right">send</i>
        </button>
      </div>
    </div>
  );
};

ConcernFeedbackModal.propTypes = {
  sendFeedback: PropTypes.func.isRequired
};

export default connect(
  null,
  {
    sendFeedback
  }
)(ConcernFeedbackModal);
