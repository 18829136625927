import React, { useEffect } from 'react';
import M from 'materialize-css';

const MainOptionsForm = ({ formData, setFormData }) => {
  const { main } = formData;

  // Call M function to format fields
  useEffect(() => {
    M.updateTextFields();
  }, [formData]);

  const { sitename, copyright } = main;

  const onChange = e =>
    setFormData({
      ...formData,
      main: {
        ...main,
        [e.target.name]: e.target.value
      }
    });

  return (
    <form>
      <div className='input-field'>
        <input
          id='sitename'
          name='sitename'
          type='text'
          className='validate'
          value={sitename}
          onChange={e => onChange(e)}
          required
        />
        <label htmlFor='sitename'>Site Name *</label>
      </div>
      <div className='input-field'>
        <input
          id='copyright'
          name='copyright'
          type='text'
          className='validate'
          value={copyright}
          onChange={e => onChange(e)}
          required
        />
        <label htmlFor='copyright'>Copyright Text *</label>
        <span className='helper-text'>
          Copyright © {new Date().getFullYear()} - {copyright}
        </span>
      </div>
    </form>
  );
};

export default MainOptionsForm;
