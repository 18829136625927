import React, { useEffect, Fragment } from 'react';
import {
  getVisitorInfo,
  calculateResults,
  restartQuiz,
} from '../../actions/quiz';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import M from 'materialize-css';
import PropTypes from 'prop-types';
import ConcernsList from '../concerns/ConcernsList';
import TextResultsModal from '../modals/TextResultsModal';
import ReactGA from 'react-ga4';
import { recordClick } from '../../actions/click';

import translatequiz from '../../locales/translatequiz.js';

const Results = ({
  quiz: { user, loading, concerns },
  settings: { quiz },
  getVisitorInfo,
  calculateResults,
  restartQuiz,
  history,
  match,
}) => {
  // Get user data
  useEffect(() => {
    getVisitorInfo();
    calculateResults(match.params.id);
  }, [getVisitorInfo, calculateResults, match.params.id]);

  useEffect(() => {
    const elems = document.querySelectorAll('.fixed-action-btn');
    M.FloatingActionButton.init(elems, {
      hoverEnabled: false,
      direction: 'bottom',
    });

    const tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips, {
      position: 'left',
    });
  }, []);

  const printResults = (e) => {
    e.preventDefault();
    ReactGA.event({
      category: 'results',
      action: 'share',
      label: 'print',
    });
    window.print();
  };

  return (
    <Fragment>
      <div className='row' onMouseDown={(e) => recordClick(e)}>
        <div className='col s12'>
          <h3 className='mb-5'>{(localStorage.getItem('quizlang') == 'es') ? quiz.thankyoutitle_es : quiz.thankyoutitle_en}</h3>
           <p className='flow-text'>{(localStorage.getItem('quizlang') == 'es') ? quiz.top3text_es : quiz.top3text_en}</p>
          {!loading && concerns && (
            <ConcernsList items={concerns} withIcon={true} />
          )}
          {/*
          <p className='flow-text center mt-5'>
            <Link to='/concerns' className='waves-effect waves-light btn-large'>
              <i className='material-icons left'>assignment</i>See all other
              parent's top concerns
            </Link>
          </p> 
          <p className='center'>
            <button
              onClick={(e) => restartQuiz(history)}
              className='primary-color waves-effect waves-light btn-large'
            >
              <i className='material-icons left'>create</i> Take the survey
              again
            </button>
          </p>
          */}
        </div>
      </div>
      <div>
          <button onClick={() => {localStorage.setItem('quizlang', 'en'); window.location.reload();}}>English</button>
          <button onClick={() => {localStorage.setItem('quizlang', 'es'); window.location.reload();}}>Español</button>
      </div>
      <div className='top-button fixed-action-btn horizontal'>
      {/* <div className='fixed-action-btn top-button center'> */}
        <a
          className='btn-large red waves-effect share-btn share-button '
          // className='btn-floating btn-large red waves-effect share-btn'
          href='#!'
        >
          {translatequiz('quiz.share')}
        </a>
        <ul>
          <li>
            <a
              className='btn-floating red waves-effect tooltipped'
              data-tooltip={translatequiz('quiz.print')}
              href='#!'
              onClick={(e) => {
                printResults(e)

                // Set user label for analytics
                let parentTag = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).parentName + " " : "No Name ";
                if(localStorage.getItem('redcap'))
                {
                  parentTag += localStorage.getItem('redcap')
                }
                ReactGA.event({
                  category: 'quiz',
                  action: 'Print Results',
                  label: parentTag,
                })
              }}
            >
              <i className='material-icons'>print</i>
            </a>
          </li>
          <li>
            <a
              className='btn-floating green waves-effect tooltipped modal-trigger'
              data-tooltip={translatequiz('quiz.text')}
              href='#textResultsModal'
              onMouseDown={() => {
                  ReactGA.event({
                    category: 'results',
                    action: 'share',
                    label: 'open sms share modal',
                  })
                }
              }
            >
              <i className='material-icons'>sms</i>
            </a>
          </li>
          <li>
            <a
              className='btn-floating blue waves-effect tooltipped email-share'
              data-tooltip={translatequiz('quiz.email')}
              rel="noreferrer noopener"
              target="_blank"
              href={`mailto:?subject=HPV Education Program&body=${translatequiz('textemail.first')}${window.location.href}${translatequiz('textemail.second')}`}
              onMouseDown={() => {
                  // Set user label for analytics
                  let parentTag = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).parentName + " " : "No Name ";
                  if(localStorage.getItem('redcap'))
                  {
                    parentTag += localStorage.getItem('redcap')
                  }
                  ReactGA.event({
                    category: 'quiz',
                    action: 'Share Results via Email',
                    label: parentTag,
                  })
                }
              }
            >
              <i className='material-icons'>email</i>
            </a>
          </li>
        </ul>
      </div>


      <TextResultsModal />
    </Fragment>
  );
};

Results.propTypes = {
  quiz: PropTypes.object.isRequired,
  getVisitorInfo: PropTypes.func.isRequired,
  calculateResults: PropTypes.func.isRequired,
  restartQuiz: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  quiz: state.quiz,
  settings: state.dashboard.siteContents,
});

export default connect(mapStateToProps, {
  getVisitorInfo,
  calculateResults,
  restartQuiz,
})(Results);
