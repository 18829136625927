import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import quiz from './quiz';
import feedback from './feedback';
import dashboard from './dashboard';
import concerns from './concerns';
import questions from './questions';

export default combineReducers({
  alert,
  auth,
  quiz,
  feedback,
  dashboard,
  concerns,
  questions
});
