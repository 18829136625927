import React, {Component, Fragment} from 'react';
import M from 'materialize-css';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {logout} from '../../actions/auth';
import {Link, withRouter} from 'react-router-dom';
import classnames from 'classnames';
import FeedbackModal from '../modals/FeedbackModal';
import {recordClick} from '../../actions/click';

import translate from '../../locales/translate.js';

class Navbar extends Component {
    componentDidMount() {
        let sidenavs = document.querySelectorAll('.sidenav');
        M.Sidenav.init(sidenavs);
    }

    render() {
        const {
            auth: {isAuthenticated, loading},
            settings: {main},
            logout,
            location,
        } = this.props;

        document.title = main.sitename;

        const authLinks = (
            <Fragment>
                <li>
                    <a href='/'>Home</a>
                </li>
                <li
                    className={classnames({
                        active: location.pathname.match('/dashboard'),
                    })}
                >
                    <a href='/dashboard'>Dashboard</a>
                </li>
                <li>
                    <a onClick={logout} href='#!'>
                        <i className='material-icons right'>exit_to_app</i> Logout
                    </a>
                </li>
            </Fragment>
        );

        const guestLinks = (
            <Fragment>
                <li>
                    <a href='/'>{translate('navbar.home')}</a>
                </li>
                {localStorage.getItem('contact') ? 
                <li>
                    <a href='/quiz'>{translate('navbar.quiz')}</a>
                </li> : 
                null}
                <li>
                    <a href='/aboutus'>
                    {translate('navbar.aboutus')}
                    </a>
                </li>
                <li>
                    <a className='modal-trigger' href='#feedbackModal'>
                    {translate('navbar.feedback')}
                    </a>
                </li>
                <li>
                    <a className='modal-trigger' href="/contact">
                    {translate('navbar.contactus')}
                    </a>
                </li>
                <li>
                    <div>
                        <button onClick={() => {localStorage.setItem('lang', 'en'); window.location.reload();}}>English</button>
                        <button onClick={() => {localStorage.setItem('lang', 'es'); window.location.reload();}}>Español</button>
                    </div>
                </li>
            </Fragment>
        );

        return (
            <Fragment>
                <div className='navbar-fixed' onMouseDown={(e) => recordClick(e)}>
                    <nav role='navigation'>
                        <div className='nav-wrapper container'>
                            <Link id='logo-container' to='/' className='brand-logo'>
                                <img src={require("../../img/HPVV-WhiteLogo.svg")} style={{width: '250px'}}/>
                            </Link>
                            <ul className='right hide-on-med-and-down'>
                                {!loading && (
                                    <Fragment>
                                        {isAuthenticated ? authLinks : guestLinks}
                                    </Fragment>
                                )}
                            </ul>

                            <a href='#!' data-target='nav-mobile' className='sidenav-trigger'>
                                <i className='material-icons'>menu</i>
                            </a>
                        </div>
                    </nav>
                </div>
                <ul id='nav-mobile' className='sidenav'>
                    {!loading && (
                        <Fragment>{isAuthenticated ? authLinks : guestLinks}</Fragment>
                    )}
                </ul>
                <FeedbackModal/>
            </Fragment>
        );
    }
}

Navbar.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    settings: state.dashboard.siteContents,
});

export default connect(mapStateToProps, {logout})(withRouter(Navbar));
