import React, { Fragment, useEffect, useState } from "react";
import M from "materialize-css";
import { Link } from "react-router-dom";
import CreateChildrenConcernForm from "./CreateChildrenConcernForm";
import { getConcern } from "../../actions/concerns";
import { adminSaveConcern } from "../../actions/dashboard";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";

const EditConcern = ({
  getConcern,
  adminSaveConcern,
  match,
  history,
  concern: { concern, loading, saving },
}) => {
  const [formData, setFormData] = useState({
    name: "",
    name_es: "",
    content: "",
    content_es: "",
    childrencontent: "",
    childrencontent_es: "",
    suggestedquestions: [],
    suggestedquestions_es: [],
    externallinks: [],
    videolink: "",
    _id: "",
  });

  // Tooltips
  useEffect(() => {
    const tooltips = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(tooltips);
  }, []);

  // Load concern
  useEffect(() => {
    getConcern(match.params.id);

    setFormData({
      name: loading || !concern.name ? "" : concern.name,
      name_es: loading || !concern.name_es ? "" : concern.name_es,
      content: loading || !concern.content ? "" : concern.content,
      content_es: loading || !concern.content_es ? "" : concern.content_es,
      childrencontent:
        loading || !concern.childrencontent ? "" : concern.childrencontent,
      childrencontent_es:
        loading || !concern.childrencontent_es ? "" : concern.childrencontent_es,
      suggestedquestions:
        loading || !concern.suggestedquestions
          ? ""
          : concern.suggestedquestions,
      suggestedquestions_es:
          loading || !concern.suggestedquestions
            ? ""
            : concern.suggestedquestions_es,
      externallinks:
        loading || !concern.externallinks ? "" : concern.externallinks,
      videolink: loading || !concern.videolink ? "" : concern.videolink,
      _id: loading || !concern._id ? "" : concern._id,
    });

    // eslint-disable-next-line
  }, [match.params.id, getConcern, loading]);

  // Update Materialize fields
  useEffect(() => {
    M.updateTextFields();
  }, [concern]);

  return (
    <Fragment>
      {loading || saving ? (
        <Spinner />
      ) : (
        <Fragment>
          <div className="row">
            <div className="col s12">
              <h3>
                <Link
                  to="/dashboard/concerns"
                  className="tooltipped"
                  data-tooltip="Back to Concerns"
                >
                  <i className="material-icons small">arrow_back</i>
                </Link>{" "}
                Edit Kids Content for Concern
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div className="card">
                <div className="card-content grey lighten-4">
                  <CreateChildrenConcernForm
                    formData={formData}
                    setFormData={setFormData}
                    loading={loading}
                    type="edit"
                  />
                </div>
                <div className="card-action">
                  <button
                    type="button"
                    className="btn waves-effect"
                    onClick={(e) => adminSaveConcern(formData, history)}
                  >
                    <i className="material-icons left">save</i> Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

EditConcern.propTypes = {
  concern: PropTypes.object.isRequired,
  getConcern: PropTypes.func.isRequired,
  adminSaveConcern: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  concern: state.concerns,
});

export default connect(mapStateToProps, {
  getConcern,
  adminSaveConcern,
})(EditConcern);
