import React, { Fragment } from 'react';

const NotFound = () => {
  return (
    <Fragment>
      <div className='center'>
        <i className='material-icons large'>mood_bad</i>
        <h3 className='mt-0'> Page Not Found</h3>
        <p className='large'>Sorry, this page does not exist.</p>
      </div>
    </Fragment>
  );
};

export default NotFound;
