import React, { Fragment, useEffect, useState } from 'react';
import M from 'materialize-css';
import { Link } from 'react-router-dom';
import CreateQuestionForm from './CreateQuestionForm';
import { getQuestion } from '../../actions/questions';
import { adminSaveQuestion } from '../../actions/dashboard';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const EditQuestion = ({
  getQuestion,
  adminSaveQuestion,
  match,
  history,
  question: { question, loading },
}) => {
  const [formData, setFormData] = useState({
    text: '',
    text_es: '',
    concern: '',
    description: '',
    labelA: '',
    labelB: '',
    labelC: '',
    labelD: '',
    labelE: '',
    labelA_es: '',
    labelB_es: '',
    labelC_es: '',
    labelD_es: '',
    labelE_es: '',
    _id: '',
  });

  // Tooltips
  useEffect(() => {
    const tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips);
  }, []);

  // Load question
  useEffect(() => {
    getQuestion(match.params.id);

    setFormData({
      _id: loading || !question._id ? '' : question._id,
      text: loading || !question.text ? '' : question.text,
      text_es: loading || !question.text_es ? '' : question.text_es,
      concern: loading || !question.concern ? '' : question.concern,
      description: loading || !question.description ? '' : question.description,
      labelA: loading || !question.labelA ? '' : question.labelA,
      labelB: loading || !question.labelB ? '' : question.labelB,
      labelC: loading || !question.labelC ? '' : question.labelC,
      labelD: loading || !question.labelD ? '' : question.labelD,
      labelE: loading || !question.labelE ? '' : question.labelE,
      labelA_es: loading || !question.labelA_es ? '' : question.labelA_es,
      labelB_es: loading || !question.labelB_es ? '' : question.labelB_es,
      labelC_es: loading || !question.labelC_es ? '' : question.labelC_es,
      labelD_es: loading || !question.labelD_es ? '' : question.labelD_es,
      labelE_es: loading || !question.labelE_es ? '' : question.labelE_es,
    });
    // eslint-disable-next-line
  }, [match.params.id, getQuestion, loading]);

  // Update Materialize fields
  useEffect(() => {
    M.updateTextFields();
    let elems = document.getElementById('concern');
    M.FormSelect.init(elems);
  }, [question]);

  return (
    <Fragment>
      <div className='row'>
        <div className='col s12'>
          <h3>
            <Link
              to='/dashboard/questions'
              className='tooltipped'
              data-tooltip='Back to Questions'
            >
              <i className='material-icons small'>arrow_back</i>
            </Link>{' '}
            Edit Question
          </h3>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='card'>
            <div className='card-content grey lighten-4'>
              <CreateQuestionForm
                formData={formData}
                setFormData={setFormData}
                type='edit'
              />
            </div>
            <div className='card-action'>
              <button
                type='button'
                className='btn waves-effect'
                onClick={(e) => adminSaveQuestion(formData, history)}
              >
                <i className='material-icons left'>save</i> Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

EditQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  getQuestion: PropTypes.func.isRequired,
  adminSaveQuestion: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  question: state.questions,
});

export default connect(mapStateToProps, {
  getQuestion,
  adminSaveQuestion,
})(EditQuestion);
