import React, { useState } from 'react';
import { connect } from 'react-redux';
import { setAlert } from '../../actions/alert';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import { Redirect } from 'react-router-dom';
import { recordClick } from '../../actions/click';

const Login = ({ setAlert, login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    login(email, password);
  };

  // Redirect if logged in
  if (isAuthenticated) {
    return <Redirect to='/dashboard' />;
  }

  return (
    <div className='section' onMouseDown={(e) => recordClick(e)}>
      <div className='row center'>
        <h4 className='primary-color-text'>Login:</h4>
        <form className='col s12' onSubmit={e => onSubmit(e)}>
          <div className='row'>
            <div className='input-field col s12 m6 offset-m3'>
              <i className='material-icons prefix'>email</i>
              <input
                id='email'
                name='email'
                type='email'
                value={email}
                onChange={e => onChange(e)}
                required
              />
              <label htmlFor='email'>Email</label>
            </div>
          </div>
          <div className='row'>
            <div className='input-field col s12 m6 offset-m3'>
              <i className='material-icons prefix'>lock</i>
              <input
                id='password'
                name='password'
                type='password'
                value={password}
                onChange={e => onChange(e)}
              />
              <label htmlFor='password'>Password</label>
            </div>
          </div>
          <button
            className='btn waves-effect waves-light primary-color'
            type='submit'
            name='action'
          >
            Login
            <i className='material-icons right'>send</i>
          </button>
        </form>
      </div>
    </div>
  );
};

Login.propTypes = {
  setAlert: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(
  mapStateToProps,
  {
    setAlert,
    login
  }
)(Login);
