import { GET_CONCERNS, CONCERNS_ERROR, GET_CONCERN } from "./types";
import axios from "axios";

// Get concerns
export const getConcerns = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/concerns");

    dispatch({
      type: GET_CONCERNS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CONCERNS_ERROR,
      payload: err,
    });
  }
};

// Get concerns names
export const getConcernNames = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/concerns/all");

    dispatch({
      type: GET_CONCERNS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CONCERNS_ERROR,
      payload: err,
    });
  }
};

// Get concern by ID
export const getConcern = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/concerns/${id}`);
    dispatch({
      type: GET_CONCERN,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CONCERNS_ERROR,
      payload: err,
    });
  }
};
