import React, { Fragment, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import Delta from "quill-delta";
import ImageResize from "quill-image-resize-module-react";
import { ImageDrop } from "quill-image-drop-module";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import Spinner from "../layout/Spinner";

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageDrop", ImageDrop);

const CreateConcernForm = ({
  formData,
  setFormData,
  loading,
  type = "edit",
}) => {
  let {
    name,
    name_es,
    content,
    content_es,
    childrencontent,
    childrencontent_es,
    suggestedquestions,
    suggestedquestions_es,
    externallinks,
    videolink,
  } = formData;

  const [uploading, setUploading] = useState(false);
  const [quillRef, setQuillRef] = useState("");

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onChangeCustom = (value, name) =>
    setFormData({
      ...formData,
      [name]: value,
    });

  const addQuestion = () => {
    setFormData({
      ...formData,
      suggestedquestions: [...suggestedquestions, ""],
    });
  };

  const removeQuestion = (e, key) => {
    e.preventDefault();
    setFormData({
      ...formData,
      suggestedquestions: formData.suggestedquestions.filter(
        (e, k) => k !== key
      ),
    });
  };

  const onChangeQuestion = (key, e) => {
    let items = [...suggestedquestions];
    let item = items[key];
    item = e.target.value;
    items[key] = item;
    setFormData({
      ...formData,
      suggestedquestions: items,
    });
  };

  const addLink = () => {
    setFormData({
      ...formData,
      externallinks: [
        ...externallinks,
        {
          name: "",
          url: "",
        },
      ],
    });
    console.log(formData);
  };

  const removeLink = (e, key) => {
    e.preventDefault();
    setFormData({
      ...formData,
      externallinks: formData.externallinks.filter((e, k) => k !== key),
    });
  };

  const onChangeLink = (key, attr, e) => {
    let items = [...externallinks];
    let item = items[key];
    item[attr] = e.target.value;
    items[key] = item;
    setFormData({
      ...formData,
      externallinks: items,
    });
  };

  const uploadFunction = (e) => {
    let selectedFile = e.target.files[0];

    let form = new FormData();
    form.append("file", selectedFile);
    form.append("name", selectedFile.name);
    setUploading(true);
    //upload image to server
    axios
      .post("/api/s3/upload", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((r) => {
        console.log("success", r);

        let range = quillRef.getEditor().getSelection(true);

        quillRef.getEditor().updateContents(
          new Delta().retain(range.index).delete(range.length).insert({
            image: r.data.Data[0].Location,
          }),
          "user"
        );
        setUploading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  return (
  <Fragment>
    <Fragment>
      <div className="input-field pt-3">
        {(type === "edit" && !loading && childrencontent) ||
        (type === "create" && !loading) ? (
          <Fragment>
            {uploading ? <Spinner /> : ""}
            <input
              type="file"
              onChange={(e) => uploadFunction(e)}
              id="file"
              hidden
            />
            <ReactQuill
              ref={(el) => {
                setQuillRef(el);
              }}
              value={childrencontent}
              onChange={(value) => onChangeCustom(value, "childrencontent")}
              onFocus={() => {
                var toolbar = quillRef.getEditor().getModule("toolbar");
                toolbar.addHandler("image", function () {
                  document.getElementById("file").click();
                });
              }}
              modules={{
                clipboard: {
                  matchVisual: false,
                },
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                    { align: [] },
                  ],
                  ["link", "image", "video"],
                ],
                imageResize: {
                  parchment: Quill.import("parchment"),
                  modules: ["Resize", "DisplaySize"],
                },
                imageDrop: true,
              }}
            />
          </Fragment>
        ) : (
          ""
        )}
        <label className="active">Page Content (English)</label>
      </div>
    </Fragment>
    <Fragment>
    <div className="input-field pt-3">
      {(type === "edit" && !loading && childrencontent_es) ||
      (type === "create" && !loading) ? (
        <Fragment>
          {uploading ? <Spinner /> : ""}
          <input
            type="file"
            onChange={(e) => uploadFunction(e)}
            id="file"
            hidden
          />
          <ReactQuill
            ref={(el) => {
              setQuillRef(el);
            }}
            value={childrencontent_es}
            onChange={(value) => onChangeCustom(value, "childrencontent_es")}
            onFocus={() => {
              var toolbar = quillRef.getEditor().getModule("toolbar");
              toolbar.addHandler("image", function () {
                document.getElementById("file").click();
              });
            }}
            modules={{
              clipboard: {
                matchVisual: false,
              },
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                  { align: [] },
                ],
                ["link", "image", "video"],
              ],
              imageResize: {
                parchment: Quill.import("parchment"),
                modules: ["Resize", "DisplaySize"],
              },
              imageDrop: true,
            }}
          />
        </Fragment>
      ) : (
        ""
      )}
      <label className="active">Page Content (Spanish)</label>
    </div>
  </Fragment>
</Fragment>
    
  );
};

export default CreateConcernForm;
