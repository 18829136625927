import React, { useEffect, useState, Fragment } from 'react';
import M from 'materialize-css';
import { getVisitorInfo, restartQuiz } from '../../actions/quiz';
import { getConcerns } from '../../actions/concerns';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ConcernsList from './ConcernsList';
import TextResultsModal from '../modals/TextResultsModal';
import { recordClick } from '../../actions/click';
import ReactGA from 'react-ga4';
import PropTypes from 'prop-types';
import translatequiz from '../../locales/translatequiz.js';

const AllConcerns = ({
  quiz: { user, userResultsId },
  concerns: { concerns },
  settings: { quiz },
  getVisitorInfo,
  getConcerns,
  restartQuiz,
  history,
  match,
}) => {
  // Get user data
  useEffect(() => {
    getVisitorInfo();
    getConcerns();
  }, [getVisitorInfo, getConcerns]);

  const [concernTimer, setConcernTimer] = useState(new Date());
  const [concernTitle, setConcernTitle] = useState("");

  useEffect(() => {
    const fixedBtn = document.querySelectorAll('.fixed-action-btn');
    M.FloatingActionButton.init(fixedBtn, {
      hoverEnabled: false,
    });

    let elems = document.querySelectorAll('.collapsible');
    M.Collapsible.init(elems);

    // Handle GA event to keep track of how long user was on a child concern page
    if(match.params.id)
    {
      // Detect when leaving page
      window.addEventListener("beforeunload", handleUnload);

      // Detect when changing page within site
      const unlisten = history.listen((location, action) => {
        handleUnload()
      })

      // stop the listener when component unmounts
      return () => {
        unlisten();
        window.removeEventListener("beforeunload", handleUnload);
      };
    }
    
  }, []);

  // Check for the child concern's title when it loads.
  useEffect(() => {
    if(concerns.length > 0)
    {
      if(concerns[0].name && concerns[0].name != "" && match.params.id)
      {
        localStorage.setItem('concernTitle' + match.params.id, concerns[0].name);
      }
    }

  }, [concerns]);

  // Calculate the time the user has spent on the page and send GA event
  const handleUnload = function(e) {
    if(match.params.id)
    {
      const endTime = new Date();

      const calculatedSeconds = Math.abs(endTime.getTime() - concernTimer.getTime()) / 1000;

      // Set user label for analytics
      let parentTag = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).parentName + " " : "No Name ";
      if(localStorage.getItem('redcap'))
      {
        parentTag += localStorage.getItem('redcap')
      }

      ReactGA.event({
        category: localStorage.getItem('concernTitle' + match.params.id),
        action: "Child Page Duration",
        label: parentTag,
        value: calculatedSeconds,
      })

      localStorage.removeItem('concernTitle' + match.params.id)
    }
  }
 

  if (match.params.id !== undefined) {
    concerns = concerns.filter(function (el) {
      return el._id == match.params.id;
    });
    if (concerns.length) {
      const language = localStorage.getItem('quizlang') || 'en'; // Default to 'en' if no language is set
      
      // Use language-specific fields based on the value of quizlang
      quiz.topparentconcernstitle = language === 'es' ? concerns[0].name_es : concerns[0].name;
      quiz.topparentconcernstext = language === 'es' ? concerns[0].childrencontent_es : concerns[0].childrencontent;
    }    
  }

  return (
    <div className='row' onMouseDown={(e) => recordClick(e)}
      onLoad={() => {
        ReactGA.event({
        category: "concern",
        action: "load concern",
        label: window.location.href
      });
      window.addEventListener('beforeunload',() => {
        ReactGA.event({
          category: "concern",
          action: "leave concern",
          label: window.location.href
        })
      })
      }
    }
    >
      <div className='col s12'>
        {/* <h3 className="mb-5">{quiz.topparentconcernstitle}</h3> */}
        {!match.params.id ? (
          <p className='flow-text'>{quiz.topparentconcernstext}</p>
        ) : (
          <div
            className='quill-content pb-3'
            dangerouslySetInnerHTML={{ __html: quiz.topparentconcernstext }}
          />
        )}
        {!match.params.id ? <ConcernsList items={concerns} /> : ''}
        {userResultsId ? (
          <Fragment>
            {/*
            <p className='flow-text center mt-5'>
              <Link
                to={`/results/${userResultsId}`}
                className='waves-effect waves-light btn-large'
              >
                <i className='material-icons left'>arrow_back</i> Get back to
                your results
              </Link>
            </p>
            
            <p className='flow-text center'>
              <button
                onClick={(e) => restartQuiz(history)}
                className='primary-color waves-effect waves-light btn-large'
              >
                <i className='material-icons left'>create</i> Take the survey
                again
              </button>
            </p>
            */}
          </Fragment>
        ) : !match.params.id ? (
          <p className='flow-text center mt-5'>
            <Link to='/quiz' className='waves-effect waves-light btn-large'>
              <i className='material-icons left'>assignment</i> Take the survey
              to identify your top 3 concerns
            </Link>
          </p>
        ) : (
          ''
        )}
      </div>
      {/* <div className='fixed-action-btn'>
        <a
          className='btn-floating btn-large red waves-effect tooltipped'
          href='#!'
        >
          SHARE
        </a>
        <ul>
          <li>
            <a
              className='btn-floating green waves-effect tooltipped modal-trigger'
              data-tooltip='Text the link to your results'
              href='#textResultsModal'
            >
              <i className='material-icons'>sms</i>
            </a>
          </li>
          <li>
            <a
              className='btn-floating blue waves-effect tooltipped email-share'
              data-tooltip='Send the link to your results by email'
              href={`mailto:?subject=Get the Facts!&body=Hi, checkout this link: ${window.location.href}`}
            >
              <i className='material-icons'>email</i>
            </a>
          </li>
        </ul>
      </div> */}
      <TextResultsModal />
    </div>
  );
};

AllConcerns.propTypes = {
  quiz: PropTypes.object.isRequired,
  concerns: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  getVisitorInfo: PropTypes.func.isRequired,
  getConcerns: PropTypes.func.isRequired,
  restartQuiz: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  quiz: state.quiz,
  concerns: state.concerns,
  settings: state.dashboard.siteContents,
});

export default connect(mapStateToProps, {
  getVisitorInfo,
  getConcerns,
  restartQuiz,
})(AllConcerns);
