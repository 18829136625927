import React, {Fragment, useEffect} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Landing from './components/layout/Landing';
import Routes from './components/routing/Routes';
// Redux
import {Provider} from 'react-redux';
import store from './store';
import {loadUser} from './actions/auth';
import {adminLoadContents} from './actions/dashboard';
import setAuthToken from './utils/setAuthToken';
import ReactGA from 'react-ga4';
import axios from 'axios';
import {createBrowserHistory} from 'history';

import './App.scss';

if (localStorage.token) {
    setAuthToken(localStorage.token);
}

const history = createBrowserHistory();
let currentLocation = ""
let entryTime = 0;
// Initialize google analytics page view tracking
history.listen((location, action) => {
    let prevLocation = currentLocation;
    ReactGA.set({page: location.pathname}); // Update the user's current page
    ReactGA.event({
        category: "Navigation",
        action: "page_leave",
        label: currentLocation != '' ? currentLocation : 'Page Refresh'
    });

    if (entryTime !== 0) {
        ReactGA.event({
            category: "Navigation",
            action: "page_duration",
            label: prevLocation != '' ? `${prevLocation} : ${Math.floor((Date.now() - entryTime) / 1000)}sec` : 'Page Refresh',
            value: Date.now() - entryTime
        });
    }

    entryTime = Date.now()
    ReactGA.event({
        category: "Navigation",
        action: "page_view",
        label: `${location.pathname}${location.search}${location.hash}`
    });
    currentLocation = `${location.pathname}${location.search}${location.hash}`
});


const App = () => {
    useEffect(() => {
        store.dispatch(loadUser());
        store.dispatch(adminLoadContents());
    }, []);

    // Only track Google Analytics data if we're on the prod site 
    if( window.location.hostname.includes("hpvvaxfacts") )
    {
        const trackingId = 'G-LLH1TD3957'; // Replace with your Google Analytics tracking ID
        ReactGA.initialize([{trackingId}]);
    }
    

    return (
        <Provider store={store}>
            <Router history={history}>
                <Fragment>
                    <Navbar/>
                    <Switch>
                        <Route exact path='/' component={Landing}/>
                        <Route component={Routes}/>
                    </Switch>
                </Fragment>
            </Router>
        </Provider>
    );
};

export default App;
