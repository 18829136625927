import React, { Component, Fragment } from 'react';
import M from 'materialize-css';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../layout/Spinner';
import { recordClick } from '../../actions/click';

import home1 from '../../img/home-1.jpg';
import home2 from '../../img/home-2.png';

import {
  getContactDetails,
} from '../../actions/quiz';



class Landing extends Component {
  componentDidMount() {
    if (this.props.match && this.props.match.params && this.props.match.params.contactId){
      const loadContactInfo = async () => {
        this.props.getContactDetails(this.props.match.params.contactId)
      }
      loadContactInfo()
    }
    let elems = document.querySelectorAll('.parallax');
    setTimeout(() => {
      M.Parallax.init(elems, { responsiveThreshold: 9999999 });
    }, 1000);
  }


  render() {
    const language = localStorage.getItem('lang') || 'en';

    const {
      settings: {
          main,
          homePage
      },
      loading,
    } = this.props;

    const maintitle = language === 'en' ? homePage.maintitle_en : homePage.maintitle_es;
    const subtitle = language === 'en' ? homePage.subtitle_en : homePage.subtitle_es;
    const calltoaction = language === 'en' ? homePage.calltoaction_en : homePage.calltoaction_es;
    const title1 = language === 'en' ? homePage.title1_en : homePage.title1_es;
    const title2 = language === 'en' ? homePage.title2_en : homePage.title2_es;
    const title3 = language === 'en' ? homePage.title3_en : homePage.title3_es;
    const headline1 = language === 'en' ? homePage.headline1_en : homePage.headline1_es;
    const headline2 = language === 'en' ? homePage.headline2_en : homePage.headline2_es;
    const text1 = language === 'en' ? homePage.text1_en : homePage.text1_es;

    const contactId = localStorage.getItem('contact');

    return (
      <Fragment>
        {!main || loading ? (
          <Spinner />
        ) : (
          <div className='home-page' onMouseDown={(e) => recordClick(e)}>
            <div className='section'>
              <div className='container'>
                <h3 className='header center '>{maintitle}</h3>
                <div className='row center'>
                  <h5 className='header col s12 light'>{subtitle}</h5>
                </div>
              </div>
            </div>
            <div>
              <div style={{ opacity: '0.5', margin: '-50px 0' }}>
                <img src={home2} alt='' style={{ width: '100vw' }} />
              </div>
            </div>

            <div className='section home-top' style={{ padding: '30px 0 0px' }}>
              <div className='container center'>
                <h5>{headline1}</h5>
                <h6>{text1}</h6>
              </div>
            </div>

            <div
              className='parallax-container'
              style={{
                minHeight: '270px',
                height: 'auto',
                padding: '0 0 40px',
              }}
            >
              <div className='container'>
                <div className='section'>
                  <div className='row'>
                    <div className='col s12 center'>
                      <h5>{headline2}</h5>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col s12 m4'>
                      <div className='icon-block'>
                        <h2 className='center blue-text text-darken-1'>
                          <i className='material-icons large'>
                            assignment_turned_in
                          </i>
                        </h2>
                        <h6 className='center'>{title1}</h6>
                      </div>
                    </div>

                    <div className='col s12 m4'>
                      <div className='icon-block'>
                        <h2 className='center blue-text text-darken-1'>
                          <i className='material-icons large'>receipt</i>
                        </h2>
                        <h6 className='center'>{title2}</h6>
                      </div>
                    </div>

                    <div className='col s12 m4'>
                      <div className='icon-block'>
                        <h2 className='center blue-text text-darken-1'>
                        <img src={require("../../img/Elements_ComputerIcon.svg")} style={{ width: '6.8rem' }}/>
                        </h2>
                        <h6 className='center' style={{marginTop: "-1rem"}}>{title3}</h6>
                      </div>
                    </div>
                  </div>
                  {contactId ? (
                  <div className='row center mt-5'>
                    <Link
                      to='/quiz'
                      className='btn-large waves-effect waves-light'
                    >
                      {calltoaction}
                    </Link>
                  </div>) : ''}
                </div>
              </div>
              {/* <div className='parallax' style={{ opacity: '0.2' }}>
                <img src={home1} alt='' />
              </div> */}
            </div>
            <footer className='page-footer'>
              <div className='footer-copyright'>
                <div className='container'>
                  Copyright © {new Date().getFullYear()} {main.copyright}
                </div>
              </div>
            </footer>
          </div>
        )}
      </Fragment>
    );
  }
}

Landing.propTypes = {
  settings: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  settings: state.dashboard.siteContents,
});

export default connect(mapStateToProps, dispatch => ({
  getContactDetails: (id) => dispatch(getContactDetails(id))
}))(Landing);
