import {
  QUIZ_ERROR,
  GET_VISITOR_INFO,
  SAVE_VISITOR_INFO,
  QUESTION_LOADED,
  QUESTION_ANSWERED,
  QUIZ_RESTARTED,
  GET_QUESTIONS,
  LOAD_RESULTS,
  LOAD_CONTACT,
  CLEAR_CONTACT
} from '../actions/types';

const initialState = {
  user: JSON.parse(localStorage.getItem('user')) || {},
  questions: [],
  question: {},
  answers: [],
  error: {},
  concerns: [],
  contact: {},
  userResultsId: null,
  loading: true
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_CONTACT:
      return {
        ...state,
        contact: {}
      };
    case LOAD_CONTACT:
      return {
        ...state,
        contact: payload,
        loading: false
      };
    case LOAD_RESULTS:
      return {
        ...state,
        concerns: payload,
        loading: false
      };
    case GET_QUESTIONS:
      return {
        ...state,
        questions: payload,
        loading: false
      };
    case QUESTION_LOADED:
      const { question, answers } = payload;
      return {
        ...state,
        question: question,
        answers: answers,
        loading: false
      };
    case QUESTION_ANSWERED:
      return {
        ...state,
        question: {},
        answers: payload,
        loading: true
      };
    case GET_VISITOR_INFO:
    case SAVE_VISITOR_INFO:
      const userResultsId = localStorage.getItem('userResultsId');
      return {
        ...state,
        user: payload,
        userResultsId,
        loading: false
      };
    case QUIZ_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    case QUIZ_RESTARTED:
    default:
      return state;
  }
}
