import React, { useState, useEffect, Fragment } from 'react';
import {
  getQuestionByNumber,
  answerQuestion,
  clearContactDetails,
} from '../../actions/quiz';
import { recordClick } from '../../actions/click';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import M from 'materialize-css';
import SwipeListener from 'swipe-listener';
import ReactGA from 'react-ga4';

import PropTypes from 'prop-types';

import translatequiz from '../../locales/translatequiz.js';

const StartQuiz = ({
  quiz: { loading, question, answers, questions, user, contact },
  getQuestionByNumber,
  clearContactDetails,
  answerQuestion,
  history,
  match,
}) => {

  const [disableNextButton, setDisableNextButton] = useState(false);
  const [prevUrl, setPrevUrl] = useState("");

  useEffect(() => {
    const container = document.querySelector('#root');
    SwipeListener(container);

    container.addEventListener('swipe', (e) => {
      const { directions } = e.detail;

      if (directions.right) {
        simulateClick(document.getElementById('next-btn'));
      }

      if (directions.left) {
        simulateClick(document.getElementById('prev-btn'));
      }
    });

    // Detect when leaving page
    window.addEventListener("beforeunload", handleUnload);

    // Detect when changing page within site
    const unlisten = history.listen((location, action) => {
      if(!location.pathname.includes('question') && !location.pathname.includes('quiz'))
      {
        handleUnload()
      }
    })

    // stop the listener when component unmounts
    return () => {
      unlisten();
      window.removeEventListener("beforeunload", handleUnload);
    };

  }, []);

  // Trigger Google Analytic events if the user navigates away from the page before finishing quiz
  const handleUnload = (e) => {

    // Set user label for analytics
    let parentTag = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).parentName + " " : "No Name ";
    if(localStorage.getItem('redcap'))
    {
      parentTag += localStorage.getItem('redcap')
    }

    // If there are any answers but less than 10
    if(localStorage.getItem('answers') && JSON.parse(localStorage.getItem('answers')).length > 0 && JSON.parse(localStorage.getItem('answers')).length < 10)
    {
      ReactGA.event({
        category: 'quiz results',
        action: 'Left Quiz Without Finishing',
        label: parentTag,
      });
    }
    // If there are no answers
    else 
    {
      ReactGA.event({
        category: 'quiz results',
        action: 'Left Quiz Without Starting',
        label: parentTag,
        
      });
    }

    


  };

  const simulateClick = function (elem) {
    if (elem) {
      // Create our event (with options)
      const evt = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      });
      // If cancelled, don't dispatch our event
      const canceled = !elem.dispatchEvent(evt);
    }
  };

  // eslint-disable-next-line
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  // Get user data
  useEffect(() => {
    if(prevUrl != match.url)
    {
      setDisableNextButton(false);
      setPrevUrl(match.url);
    }
    
    // clearContactDetails();
    getQuestionByNumber(match.params.number);
    if (question.option) {
      setSelectedAnswer(question.option);
    } // eslint-disable-next-line
  }, [getQuestionByNumber, loading, match]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (question.option) {
      // Disable next button to prevent multiple submissions
      setDisableNextButton(true);

      answerQuestion(question, history, user);
      setSelectedAnswer(null);
    } else {
      M.toast({ html: 'Choose an option before proceeding!' });
    }
  };

  const selectOption = (val) => {
    setSelectedAnswer(val);
    question.option = val;
  };

  return (
    <Fragment>
      <div className='progress' onMouseDown={(e) => recordClick(e)}>
        <div
          className='determinate'
          style={{ width: `${question.completion}%` }}
        />
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div className='row'>
          <small className='right'>
            {question.number} of {question.amount}
          </small>
          <div className='col s12'>
            <h4 className='mb-5'>
              <span className='primary-color-text'>{match.params.number}.</span>{' '}
              {(localStorage.getItem('quizlang') == 'es') ? question.text_es : question.text}
            </h4>
            <div
              className='quill-content pb-3'
              dangerouslySetInnerHTML={{ __html: question.description }}
            />
            <form onSubmit={(e) => onSubmit(e)}>
              <ul className='collection'>
                <li className='collection-item'>
                  <label>
                    <input
                      className='with-gap'
                      name='answer'
                      type='radio'
                      onChange={(e) => selectOption('5')}
                      checked={question.option === '5'}
                    />
                    <span>{(localStorage.getItem('quizlang') == 'es') ? question.labelA_es : question.labelA}</span>
                  </label>
                </li>
                <li className='collection-item'>
                  <label>
                    <input
                      className='with-gap'
                      name='answer'
                      type='radio'
                      onChange={(e) => selectOption('4')}
                      checked={question.option === '4'}
                    />
                    <span>{(localStorage.getItem('quizlang') == 'es') ? question.labelB_es : question.labelB}</span>
                  </label>
                </li>
                <li className='collection-item'>
                  <label>
                    <input
                      className='with-gap'
                      name='answer'
                      type='radio'
                      onChange={(e) => selectOption('3')}
                      checked={question.option === '3'}
                    />
                    <span>
                    {(localStorage.getItem('quizlang') == 'es') ? question.labelC_es : question.labelC}
                    </span>
                  </label>
                </li>
                <li className='collection-item'>
                  <label>
                    <input
                      className='with-gap'
                      name='answer'
                      type='radio'
                      onChange={(e) => selectOption('2')}
                      checked={question.option === '2'}
                    />
                    <span>{(localStorage.getItem('quizlang') == 'es') ? question.labelD_es : question.labelD}</span>
                  </label>
                </li>
                <li className='collection-item'>
                  <label>
                    <input
                      className='with-gap'
                      name='answer'
                      type='radio'
                      onChange={(e) => selectOption('1')}
                      checked={question.option === '1'}
                    />
                    <span>{(localStorage.getItem('quizlang') == 'es') ? question.labelE_es : question.labelE}</span>
                  </label>
                </li>
              </ul>

              <div className='mt-5'>
                {parseFloat(match.params.number) > 1 ? (
                  // Link to previous question
                  <Link
                    to={`/question/${parseFloat(match.params.number) - 1}`}
                    className='btn left waves-effect waves-light'
                    id='prev-btn'
                  >
                    <i className='material-icons left'>arrow_back</i>
                    <span className='hide-on-small-only'>
                      {translatequiz('quiz.previous')}
                    </span>
                  </Link>
                ) : (
                  <Link
                    to={`/quiz`}
                    className='btn left waves-effect waves-light'
                    id='prev-btn'
                  >
                    <i className='material-icons left'>arrow_back</i>
                    <span className='hide-on-small-only'>{translatequiz('quiz.edit')}</span>
                  </Link>
                )}

                <button
                  type='submit'
                  className='btn primary-color right waves-effect waves-light'
                  id='next-btn'
                  disabled={disableNextButton}
                >
                  <i className='material-icons right'>arrow_forward</i>
                  {question.isLast ? translatequiz('quiz.getresults') : translatequiz('quiz.nextquestion')}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Fragment>
  );
};

StartQuiz.propTypes = {
  quiz: PropTypes.object.isRequired,
  getQuestionByNumber: PropTypes.func.isRequired,
  clearContactDetails: PropTypes.func.isRequired,
  answerQuestion: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  quiz: state.quiz,
});

export default connect(mapStateToProps, {
  getQuestionByNumber,
  clearContactDetails,
  answerQuestion,
})(StartQuiz);
