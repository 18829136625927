import React, { Fragment, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import Delta from "quill-delta";
import ImageResize from "quill-image-resize-module-react";
import { ImageDrop } from "quill-image-drop-module";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import Spinner from "../layout/Spinner";

Quill.register("modules/imageResize", ImageResize);
Quill.register("modules/imageDrop", ImageDrop);

const CreateConcernForm = ({
  formData,
  setFormData,
  loading,
  type = "create",
}) => {
  const {
    name,
    name_es,
    content,
    content_es,
    suggestedquestions,
    suggestedquestions_es,
    externallinks,
    videolink,
  } = formData;

  const [uploading, setUploading] = useState(false);
  const [quillRef, setQuillRef] = useState("");

  const onChange = (e) =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

  const onChangeCustom = (value, name) =>
    setFormData({
      ...formData,
      [name]: value,
    });

  const onChangeQuestion = (key, e) => {
    let items = [...suggestedquestions];
    let item = items[key];
    item = e.target.value;
    items[key] = item;
    setFormData({
      ...formData,
      suggestedquestions: items,
    });
  };

  const addQuestion = () => {
    setFormData({
      ...formData,
      suggestedquestions: [...suggestedquestions, ""],
    });
  };

  const removeQuestion = (e, key) => {
    e.preventDefault();
    setFormData({
      ...formData,
      suggestedquestions: formData.suggestedquestions.filter(
        (e, k) => k !== key
      ),
    });
  };

  const onChangeQuestion_es = (key, e) => {
    let items = [...suggestedquestions_es];
    let item = items[key];
    item = e.target.value;
    items[key] = item;
    setFormData({
      ...formData,
      suggestedquestions_es: items,
    });
  };

  const addQuestion_es = () => {
    setFormData({
      ...formData,
      suggestedquestions_es: [...suggestedquestions_es, ""],
    });
  };

  const removeQuestion_es = (e, key) => {
    e.preventDefault();
    setFormData({
      ...formData,
      suggestedquestions_es: formData.suggestedquestions_es.filter(
        (e, k) => k !== key
      ),
    });
  };



  const addLink = () => {
    setFormData({
      ...formData,
      externallinks: [
        ...externallinks,
        {
          name: "",
          url: "",
        },
      ],
    });
    console.log(formData);
  };

  const removeLink = (e, key) => {
    e.preventDefault();
    setFormData({
      ...formData,
      externallinks: formData.externallinks.filter((e, k) => k !== key),
    });
  };

  const onChangeLink = (key, attr, e) => {
    let items = [...externallinks];
    let item = items[key];
    item[attr] = e.target.value;
    items[key] = item;
    setFormData({
      ...formData,
      externallinks: items,
    });
  };

  const uploadFunction = (e) => {
    let selectedFile = e.target.files[0];

    let form = new FormData();
    form.append("file", selectedFile);
    form.append("name", selectedFile.name);
    setUploading(true);
    //upload image to server
    axios
      .post("/api/s3/upload", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((r) => {
        console.log("success", r);

        let range = quillRef.getEditor().getSelection(true);

        quillRef.getEditor().updateContents(
          new Delta().retain(range.index).delete(range.length).insert({
            image: r.data.Data[0].Location,
          }),
          "user"
        );
        setUploading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };

  return (
    <Fragment>
      <div className="input-field">
        <input
          id="name"
          name="name"
          type="text"
          className="validate"
          value={name}
          onChange={(e) => onChange(e)}
          required
        />
        <label htmlFor="name">Concern Name * (English)</label>
      </div>
      <div className="input-field">
        <input
          id="name_es"
          name="name_es"
          type="text"
          className="validate"
          value={name_es}
          onChange={(e) => onChange(e)}
          required
        />
        <label htmlFor="name_es">Concern Name (Spanish) *</label>
      </div>

      <div className="input-field pt-3">
        {(type === "edit" && !loading && content) ||
        (type === "create" && !loading) ? (
          <Fragment>
            {uploading ? <Spinner /> : ""}
            <input
              type="file"
              onChange={(e) => uploadFunction(e)}
              id="file"
              hidden
            />
            <ReactQuill
              ref={(el) => {
                setQuillRef(el);
              }}
              value={content}
              onChange={(value) => onChangeCustom(value, "content")}
              onFocus={() => {
                var toolbar = quillRef.getEditor().getModule("toolbar");
                toolbar.addHandler("image", function () {
                  document.getElementById("file").click();
                });
              }}
              modules={{
                clipboard: {
                  matchVisual: false,
                },
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                    { align: [] },
                  ],
                  ["link", "image", "video"],
                ],
                imageResize: {
                  parchment: Quill.import("parchment"),
                  modules: ["Resize", "DisplaySize"],
                },
                imageDrop: true,
              }}
            />
          </Fragment>
        ) : (
          ""
        )}
        <label className="active">Page Content (English)</label>
      </div>
      <div className="input-field pt-3">
        {(type === "edit" && !loading && content_es) ||
        (type === "create" && !loading) ? (
          <Fragment>
            {uploading ? <Spinner /> : ""}
            <input
              type="file"
              onChange={(e) => uploadFunction(e)}
              id="file"
              hidden
            />
            <ReactQuill
              ref={(el) => {
                setQuillRef(el);
              }}
              value={content_es}
              onChange={(value) => onChangeCustom(value, "content_es")}
              onFocus={() => {
                var toolbar = quillRef.getEditor().getModule("toolbar");
                toolbar.addHandler("image", function () {
                  document.getElementById("file").click();
                });
              }}
              modules={{
                clipboard: {
                  matchVisual: false,
                },
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                    { align: [] },
                  ],
                  ["link", "image", "video"],
                ],
                imageResize: {
                  parchment: Quill.import("parchment"),
                  modules: ["Resize", "DisplaySize"],
                },
                imageDrop: true,
              }}
            />
          </Fragment>
        ) : (
          ""
        )}
        <label className="active">Page Content (Spanish)</label>
      </div>

      <div className="row">
        <div className="col s12">
          <label className="active">Suggested Questions English</label>
          <div className="card">
            {suggestedquestions.length > 0 && (
              <div className="card-content">
                {suggestedquestions.map((q, key) => (
                  <div key={key} className="input-field">
                    <a
                      className="prefix red-text"
                      onClick={(e) => removeQuestion(e, key)}
                      href="#!"
                    >
                      <i className="material-icons">delete_forever</i>
                    </a>
                    <input
                      id={`question${key}`}
                      type="text"
                      className="validate"
                      value={q}
                      onChange={(e) => onChangeQuestion(key, e)}
                    />
                    <label htmlFor={`question${key}`}>Question</label>
                  </div>
                ))}
              </div>
            )}
            <div className="card-action">
              <button
                type="button"
                className="btn green waves-effect"
                onClick={(e) => addQuestion()}
              >
                <i className="material-icons left">question_answer</i> Add
                question
              </button>
            </div>
          </div>
        </div>
      </div>
      
      <div className="row">
        <div className="col s12">
          <label className="active">Suggested Questions Spanish</label>
          <div className="card">
            {suggestedquestions_es.length > 0 && (
              <div className="card-content">
                {suggestedquestions_es.map((q, key) => (
                  <div key={key} className="input-field">
                    <a
                      className="prefix red-text"
                      onClick={(e) => removeQuestion_es(e, key)}
                      href="#!"
                    >
                      <i className="material-icons">delete_forever</i>
                    </a>
                    <input
                      id={`question_es${key}`}
                      type="text"
                      className="validate"
                      value={q}
                      onChange={(e) => onChangeQuestion_es(key, e)}
                    />
                    <label htmlFor={`question_es${key}`}>Question</label>
                  </div>
                ))}
              </div>
            )}
            <div className="card-action">
              <button
                type="button"
                className="btn green waves-effect"
                onClick={(e) => addQuestion_es()}
              >
                <i className="material-icons left">question_answer</i> Add
                question
              </button>
            </div>
          </div>
        </div>
      </div>


      <div className="row">
        <div className="col s12">
          <label className="active">External Links</label>
          <div className="card">
            {externallinks.length > 0 && (
              <div className="card-content">
                {externallinks.map((l, key) => (
                  <div className="row mb-0" key={key}>
                    <div className="input-field col s6">
                      <a
                        className="prefix red-text"
                        onClick={(e) => removeLink(e, key)}
                        href="#!"
                      >
                        <i className="material-icons">delete_forever</i>
                      </a>
                      <input
                        id={`linkname${key}`}
                        type="text"
                        className="validate"
                        value={l.name}
                        onChange={(e) => onChangeLink(key, "name", e)}
                      />
                      <label htmlFor={`linkname${key}`}>Link Description</label>
                    </div>
                    <div className="input-field col s6">
                      <input
                        id={`linkurl${key}`}
                        type="url"
                        className="validate"
                        value={l.url}
                        onChange={(e) => onChangeLink(key, "url", e)}
                      />
                      <label htmlFor={`linkurl${key}`}>Link URL</label>
                    </div>
                  </div>
                ))}
              </div>
            )}
            <div className="card-action">
              <button
                type="button"
                className="btn green waves-effect"
                onClick={(e) => addLink()}
              >
                <i className="material-icons left">insert_link</i> Add link
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="input-field">
        <input
          id="videolink"
          name="videolink"
          type="text"
          className="validate"
          value={videolink}
          onChange={(e) => onChange(e)}
        />
        <label htmlFor="videolink">Video Link</label>
      </div>
    </Fragment>
  );
};

export default CreateConcernForm;
