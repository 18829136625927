import {
  ADMIN_LOAD_CONTENTS,
  ADMIN_ERROR,
  ADMIN_SAVE_CONTENTS,
  ADMIN_LOAD_QUESTIONS,
  ADMIN_SAVE_QUESTION,
  ADMIN_TOGGLE_QUESTION_STATUS,
  ADMIN_LOAD_CONCERNS,
  ADMIN_SAVE_CONCERN,
  ADMIN_TOGGLE_CONCERN_STATUS,
  ADMIN_LOAD_FEEDBACKS,
  ADMIN_CREATE_CONTACT,
  ADMIN_LOAD_CONTACTS,
  ADMIN_DELETE_CONTACTS,
  ADMIN_LOAD_USERS,
  ADMIN_LOAD_USER,
  ADMIN_CLEAR_USER,
  ADMIN_DELETE_USER,
  ADMIN_SAVING_CONCERN
} from '../actions/types';
const initialState = {
  siteContents: {
    main: {
      sitename: '',
      copyright: ''
    },
    homePage: {
      maintitle: '',
      subtitle: '',
      calltoaction: '',
      headline1: '',
      text1: '',
      headline2: '',
      icon1: '',
      title1: '',
      icon2: '',
      title2: '',
      icon3: '',
      title3: ''
    },
    quiz: {
      starttitle: '',
      thankyoutitle: '',
      top3text: '',
      topparentconcernstitle: '',
      topparentconcernstext: ''
    }
  },
  contacts: [],
  questions: [],
  concerns: [],
  feedbacks: [],
  users: [],
  user: {},
  loading: true,
  saving: false
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADMIN_LOAD_QUESTIONS:
    case ADMIN_SAVE_QUESTION:
    case ADMIN_TOGGLE_QUESTION_STATUS:
      return {
        ...state,
        questions: payload,
        loading: false
      };
    case ADMIN_LOAD_CONCERNS:
    case ADMIN_SAVE_CONCERN:
    case ADMIN_TOGGLE_CONCERN_STATUS:
      return {
        ...state,
        concerns: payload,
        loading: false,
        saving: true
      };
    case ADMIN_SAVING_CONCERN:
      return {
        ...state,
        saving: true
      };
    case ADMIN_LOAD_USERS:
    case ADMIN_DELETE_USER:
      return {
        ...state,
        users: payload,
        loading: false
      };
    case ADMIN_LOAD_USER:
      return {
        ...state,
        user: payload,
        loading: false
      };
    case ADMIN_CLEAR_USER:
      return {
        ...state,
        user: {},
        loading: true
      };
    case ADMIN_LOAD_CONTENTS:
    case ADMIN_SAVE_CONTENTS:
      return {
        ...state,
        siteContents: {
          ...state.siteContents,
          ...payload
        },
        loading: false
      };
    case ADMIN_LOAD_FEEDBACKS:
      return {
        ...state,
        feedbacks: payload,
        loading: false
      };
    case ADMIN_CREATE_CONTACT:
    case ADMIN_LOAD_CONTACTS:
    case ADMIN_DELETE_CONTACTS:
      return {
        ...state,
        contacts: payload,
        loading: false
      };
    case ADMIN_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}
