export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";

export const SAVE_VISITOR_INFO = "SAVE_VISITOR_INFO";
export const GET_VISITOR_INFO = "GET_VISITOR_INFO";
export const QUIZ_ERROR = "QUIZ_ERROR";
export const QUESTION_LOADED = "QUESTION_LOADED";
export const QUESTION_ANSWERED = "QUESTION_ANSWERED";
export const QUIZ_RESTARTED = "QUIZ_RESTARTED";

export const GET_CONCERNS = "GET_CONCERNS";
export const GET_CONCERN = "GET_CONCERN";
export const CLEAR_CONCERN = "CLEAR_CONCERN";
export const CONCERNS_ERROR = "CONCERNS_ERROR";

export const GET_QUESTION = "GET_QUESTION";
export const GET_QUESTIONS = "GET_QUESTIONS";
export const CLEAR_QUESTION = "CLEAR_QUESTION";
export const QUESTION_ERROR = "QUESTION_ERROR";

export const LOAD_RESULTS = "LOAD_RESULTS";
export const SAVE_RESULT = "SAVE_RESULT";
export const SAVE_RESULTS_ORDER = "SAVE_RESULTS_ORDER";
export const TEXT_RESULT = "TEXT_RESULT";
export const RESULTS_ERROR = "RESULTS_ERROR";

export const LOAD_CONTACT = "LOAD_CONTACT";
export const CLEAR_CONTACT = "CLEAR_CONTACT";

export const SEND_FEEDBACK = "SEND_FEEDBACK";

export const ADMIN_ERROR = "ADMIN_ERROR";
export const ADMIN_LOAD_CONTENTS = "ADMIN_LOAD_CONTENTS";
export const ADMIN_SAVE_CONTENTS = "ADMIN_SAVE_CONTENTS";
export const ADMIN_LOAD_QUESTIONS = "ADMIN_LOAD_QUESTIONS";
export const ADMIN_SAVE_QUESTION = "ADMIN_SAVE_QUESTION";
export const ADMIN_REORDER_QUESTIONS = "ADMIN_REORDER_QUESTIONS";
export const ADMIN_TOGGLE_QUESTION_STATUS = "ADMIN_TOGGLE_QUESTION_STATUS";
export const ADMIN_LOAD_CONCERNS = "ADMIN_LOAD_CONCERNS";
export const ADMIN_SAVE_CONCERN = "ADMIN_SAVE_CONCERN";
export const ADMIN_SAVING_CONCERN = "ADMIN_SAVING_CONCERN";
export const ADMIN_REORDER_CONCERNS = "ADMIN_REORDER_CONCERNS";
export const ADMIN_TOGGLE_CONCERN_STATUS = "ADMIN_TOGGLE_CONCERN_STATUS";
export const ADMIN_SEND_SMS = "ADMIN_SEND_SMS";
export const ADMIN_LOAD_FEEDBACKS = "ADMIN_LOAD_FEEDBACKS";
export const ADMIN_CREATE_CONTACT = "ADMIN_CREATE_CONTACT";
export const ADMIN_LOAD_CONTACTS = "ADMIN_LOAD_CONTACTS";
export const ADMIN_DELETE_CONTACTS = "ADMIN_DELETE_CONTACTS";
export const ADMIN_LOAD_USERS = "ADMIN_LOAD_USERS";
export const ADMIN_LOAD_USER = "ADMIN_LOAD_USER";
export const ADMIN_CLEAR_USER = "ADMIN_CLEAR_USER";
export const ADMIN_DELETE_USER = "ADMIN_DELETE_USER";
