import translations from './translations.json';

const translatequiz = (key) => {
    const language = localStorage.getItem('quizlang') || 'en'; // Default to 'en' if no language is set
    const keys = key.split('.'); // Split the key by dots to access nested properties

    let translation = translations[language];
    keys.forEach(k => {
        translation = translation ? translation[k] : null;
    });

    return translation || key; // Return the key if no translation is found
};

export default translatequiz;
