import React, { useEffect } from 'react';
import M from 'materialize-css';
// import { FilePond, registerPlugin } from 'react-filepond';
// import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

// registerPlugin(FilePondPluginImagePreview);

const HomePageOptionsForm = ({ formData, setFormData, loading }) => {
  const { homePage } = formData;

  // Call M function to format fields
  useEffect(() => {
    M.updateTextFields();

    const textareas = document.querySelectorAll('.materialize-textarea');
    M.textareaAutoResize(textareas[0]);

    const selects = document.querySelectorAll('select');
    M.FormSelect.init(selects);
  }, [formData]);

  const {
    maintitle_en,
    subtitle_en,
    calltoaction_en,
    headline1_en,
    text1_en,
    headline2_en,
    title1_en,
    title2_en,
    title3_en,
    maintitle_es,
    subtitle_es,
    calltoaction_es,
    headline1_es,
    text1_es,
    headline2_es,
    title1_es,
    title2_es,
    title3_es
  } = homePage;

  const onChange = e => {
    setFormData({
      ...formData,
      homePage: {
        ...homePage,
        [e.target.name]: e.target.value
      }
    });
  };

  // const updateState = (field, fileItem) => {
  //   setFormData({
  //     ...formData,
  //     homePage: {
  //       ...homePage,
  //       [field]: fileItem.file.name
  //     }
  //   });
  // };

  return (
    <form>
      <div className='input-field'>
        <input
          id='maintitle_en'
          name='maintitle_en'
          type='text'
          className='validate'
          value={maintitle_en}
          onChange={e => onChange(e)}
        />
        <label htmlFor='sitename'>Main Title English</label>
      </div>
      <div className='input-field'>
        <input
          id='maintitle_es'
          name='maintitle_es'
          type='text'
          className='validate'
          value={maintitle_es}
          onChange={e => onChange(e)}
        />
        <label htmlFor='sitename'>Main Title Español</label>
      </div>

      <div className='input-field'>
        <input
          id='subtitle_en'
          name='subtitle_en'
          type='text'
          className='validate'
          value={subtitle_en}
          onChange={e => onChange(e)}
        />
        <label htmlFor='subtitle'>Subtitle English</label>
      </div>
      <div className='input-field'>
        <input
          id='subtitle_es'
          name='subtitle_es'
          type='text'
          className='validate'
          value={subtitle_es}
          onChange={e => onChange(e)}
        />
        <label htmlFor='subtitle'>Subtitle Español</label>
      </div>

      <div className='input-field'>
        <input
          id='calltoaction_en'
          name='calltoaction_en'
          type='text'
          className='validate'
          value={calltoaction_en}
          onChange={e => onChange(e)}
          required
        />
        <label htmlFor='calltoaction'>Call to Action * English</label>
      </div>
      <div className='input-field'>
        <input
          id='calltoaction_es'
          name='calltoaction_es'
          type='text'
          className='validate'
          value={calltoaction_es}
          onChange={e => onChange(e)}
          required
        />
        <label htmlFor='calltoaction'>Call to Action * Español</label>
      </div>

      <div className='input-field'>
        <input
          id='headline1_en'
          name='headline1_en'
          type='text'
          className='validate'
          value={headline1_en}
          onChange={e => onChange(e)}
        />
        <label htmlFor='headline1'>White Section Headline English</label>
      </div>
      <div className='input-field'>
        <input
          id='headline1_es'
          name='headline1_es'
          type='text'
          className='validate'
          value={headline1_es}
          onChange={e => onChange(e)}
        />
        <label htmlFor='headline1'>White Section Headline Español</label>
      </div>

      <div className='input-field'>
        <textarea
          id='text1_en'
          name='text1_en'
          className='materialize-textarea'
          value={text1_en}
          onChange={e => onChange(e)}
        />
        <label htmlFor='text1'>White Section Text English</label>
      </div>
      <div className='input-field'>
        <textarea
          id='text1_es'
          name='text1_es'
          className='materialize-textarea'
          value={text1_es}
          onChange={e => onChange(e)}
        />
        <label htmlFor='text1'>White Section Text Español</label>
      </div>

      <div className='input-field'>
        <input
          id='headline2_en'
          name='headline2_en'
          type='text'
          className='validate'
          value={headline2_en}
          onChange={e => onChange(e)}
        />
        <label htmlFor='headline2'>Last Section Headline English</label>
      </div>
      <div className='input-field'>
        <input
          id='headline2_es'
          name='headline2_es'
          type='text'
          className='validate'
          value={headline2_es}
          onChange={e => onChange(e)}
        />
        <label htmlFor='headline2'>Last Section Headline Español</label>
      </div>

      <div className='input-field'>
        <input
          id='title1_en'
          name='title1_en'
          type='text'
          className='validate'
          value={title1_en}
          onChange={e => onChange(e)}
        />
        <label htmlFor='title1'>Icon Box 1 - Title English</label>
      </div>
      <div className='input-field'>
        <input
          id='title1_es'
          name='title1_es'
          type='text'
          className='validate'
          value={title1_es}
          onChange={e => onChange(e)}
        />
        <label htmlFor='title1'>Icon Box 1 - Title Español</label>
      </div>

      {/* <div>
        <label htmlFor=''>Icon Box 1 - Image</label>
        {!loading && icon1 && (
          <FilePond
            name='file'
            server='/api/files'
            files={[
              {
                file: icon1,
                options: {
                  type: 'local'
                }
              }
            ]}
            onprocessfile={(error, fileItem) => updateState('icon1', fileItem)}
          />
        )}
      </div> */}
      <div className='input-field'>
        <input
          id='title2_en'
          name='title2_en'
          type='text'
          className='validate'
          value={title2_en}
          onChange={e => onChange(e)}
        />
        <label htmlFor='title2'>Icon Box 2 - Title English</label>
      </div>
      <div className='input-field'>
        <input
          id='title2_es'
          name='title2_es'
          type='text'
          className='validate'
          value={title2_es}
          onChange={e => onChange(e)}
        />
        <label htmlFor='title2'>Icon Box 2 - Title Español</label>
      </div>

      {/* <div>
        <label htmlFor=''>Icon Box 2 - Image</label>
        {!loading && icon2 && (
          <FilePond
            name='file'
            server='/api/files'
            files={[
              {
                file: icon2,
                options: {
                  type: 'local'
                }
              }
            ]}
            onprocessfile={(error, fileItem) => updateState('icon2', fileItem)}
          />
        )}
      </div> */}
      <div className='input-field'>
        <input
          id='title3_en'
          name='title3_en'
          type='text'
          className='validate'
          value={title3_en}
          onChange={e => onChange(e)}
        />
        <label htmlFor='title3'>Icon Box 3 - Title English</label>
      </div>
      <div className='input-field'>
        <input
          id='title3_es'
          name='title3_es'
          type='text'
          className='validate'
          value={title3_es}
          onChange={e => onChange(e)}
        />
        <label htmlFor='title3'>Icon Box 3 - Title Español</label>
      </div>

      {/* <div>
        <label htmlFor=''>Icon Box 3 - Image</label>
        {!loading && icon3 && (
          <FilePond
            name='file'
            server='/api/files'
            files={[
              {
                file: icon3,
                options: {
                  type: 'local'
                }
              }
            ]}
            onprocessfile={(error, fileItem) => updateState('icon3', fileItem)}
          />
        )}
      </div> */}
    </form>
  );
};

export default HomePageOptionsForm;
