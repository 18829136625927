import { SEND_FEEDBACK } from './types';
import M from 'materialize-css';
import axios from 'axios';
import translate from '../locales/translate.js';

export const sendFeedback = feedback => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const res = await axios.post('/api/feedbacks', { feedback }, config);

  dispatch({
    type: SEND_FEEDBACK,
    payload: res.data
  });

  const modal = document.getElementById('concernFeedbackModal');
  if (modal) { M.Modal.getInstance(modal).close() };

  const websiteModal = document.getElementById('feedbackModal');
  if (websiteModal) { M.Modal.getInstance(websiteModal).close() };

  M.toast({ html: translate('feedbacks.thankyou') });
};
