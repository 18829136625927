import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  adminExportResults,
  adminExportFeedbacks,
  adminExportBrokenResults,
} from '../../actions/dashboard';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Dashboard = ({ adminExportResults, adminExportBrokenResults, adminExportFeedbacks }) => {
  const exportResults = () => {
    setGeneratingFile(true);
    adminExportResults(setGeneratingFile);
  };

  const exportBrokenResults = () => {
    setBrokenGeneratingFile(true);
    adminExportBrokenResults(setBrokenGeneratingFile);
  };

  const exportFeedbacks = (type) => {
    setGeneratingFeedbacksFile(true);
    adminExportFeedbacks(setGeneratingFeedbacksFile, type);
  };

  const [generatingFile, setGeneratingFile] = useState(false);
  const [generatingBrokenFile, setBrokenGeneratingFile] = useState(false);
  const [generatingFeedbacksFile, setGeneratingFeedbacksFile] = useState(false);

  return (
    <Fragment>
      <div className='row'>
        <div className='col s12'>
          <h3>Welcome to your dashboard!</h3>
        </div>
      </div>
      <div className='row dashboard-cards'>
        {/* Home */}
        <div className='col s12 m6'>
          <div className='card blue lighten-5 hoverable'>
            <div className='card-content'>
              <span className='card-title'>Home Page</span>
              <p>Visit the website home page.</p>
            </div>
            <div className='card-action'>
              <Link className='btn waves-effect primary-color' to='/'>
                Go to home page
              </Link>
            </div>
          </div>
        </div>
        {/* Send Links */}
        <div className='col s12 m6'>
          <div className='card blue lighten-5 hoverable'>
            <div className='card-content'>
              <span className='card-title'>Send Links by SMS</span>
              <p>Manage your contact list to send invitation links by SMS.</p>
            </div>
            <div className='card-action'>
              <Link
                className='btn waves-effect primary-color'
                to='/dashboard/links'
              >
                Send links
              </Link>
            </div>
          </div>
        </div>
        {/* Contents */}
        <div className='col s12 m6'>
          <div className='card blue lighten-5 hoverable'>
            <div className='card-content'>
              <span className='card-title'>Page Contents</span>
              <p>
                Here you can edit all the static texts from across the
                application, including the landing page.
              </p>
            </div>
            <div className='card-action'>
              <Link
                className='btn waves-effect primary-color'
                to='/dashboard/contents'
              >
                Edit contents
              </Link>
            </div>
          </div>
        </div>
        {/* Questions */}
        <div className='col s12 m6'>
          <div className='card blue lighten-5 hoverable'>
            <div className='card-content'>
              <span className='card-title'>Questions</span>
              <p>
                Manage the questions displayed in the survey and the concerns
                they represent.
              </p>
            </div>
            <div className='card-action'>
              <Link
                className='btn waves-effect primary-color'
                to='/dashboard/questions'
              >
                Manage Questions
              </Link>
            </div>
          </div>
        </div>
        {/* Concerns */}
        <div className='col s12 m6'>
          <div className='card blue lighten-5 hoverable'>
            <div className='card-content'>
              <span className='card-title'>Concerns</span>
              <p>
                Manage the concerns available and edit their priority, content,
                images and video.
              </p>
            </div>
            <div className='card-action'>
              <Link
                className='btn waves-effect primary-color'
                to='/dashboard/concerns'
              >
                Manage Concerns
              </Link>
            </div>
          </div>
        </div>
        {/* Feedbacks */}
        <div className='col s12 m6'>
          <div className='card blue lighten-5 hoverable'>
            <div className='card-content'>
              <span className='card-title'>Feedbacks</span>
              <p>See the feedbacks given by the site <br/>users.</p>
            </div>
            <div className='card-action'>
              {/* <Link
                className='btn waves-effect primary-color'
                to='/dashboard/feedbacks'
              >
                Show feedbacks
              </Link> */}
              <button
                onClick={(e) => exportFeedbacks('website')}
                className='btn waves-effect primary-color'
              >
                {!generatingFeedbacksFile && <span>Export Website CSV</span>}
                {generatingFeedbacksFile && <span>Please wait...</span>}
              </button>
              <button
                onClick={(e) => exportFeedbacks('concern')}
                className='btn waves-effect primary-color ml-3'
              >
                {!generatingFeedbacksFile && <span>Export Concerns CSV</span>}
                {generatingFeedbacksFile && <span>Please wait...</span>}
              </button>
            </div>
          </div>
        </div>
        {/* Results */}
        <div className='col s12 m6'>
          <div className='card blue lighten-5 hoverable'>
            <div className='card-content'>
              <span className='card-title'>Results</span>
              <p>
                Export the results of the user's tests.
                <br />
                This operation can take a while.
              </p>
            </div>
            <div className='card-action'>
              <button
                onClick={(e) => exportResults()}
                className='btn waves-effect primary-color'
              >
                {!generatingFile && <span>Export CSV</span>}
                {generatingFile && <span>Please wait...</span>}
              </button>
              <button
                onClick={(e) => exportBrokenResults()}
                className='btn waves-effect primary-color ml-3'
              >
                {!generatingBrokenFile && <span>Export Problem CSV</span>}
                {generatingBrokenFile && <span>Please wait...</span>}
              </button>
            </div>
          </div>
        </div>
        {/* Admin Users */}
        <div className='col s12 m6'>
          <div className='card blue lighten-5 hoverable'>
            <div className='card-content'>
              <span className='card-title'>Admin Users</span>
              <p>Manage who can access the admin dashboard.</p>
            </div>
            <div className='card-action'>
              <Link
                className='btn waves-effect primary-color'
                to='/dashboard/users'
              >
                Manage Users
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

Dashboard.propTypes = {
  adminExportResults: PropTypes.func.isRequired,
  adminExportFeedbacks: PropTypes.func.isRequired,
  adminExportBrokenResults: PropTypes.func.isRequired,
};

export default connect(null, {
  adminExportResults,
  adminExportFeedbacks,
  adminExportBrokenResults,
})(Dashboard);
