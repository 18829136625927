import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  sendSMS,
  adminCreateContact,
  adminLoadContacts,
  adminDeleteContacts,
} from '../../actions/dashboard';
import SMSModal from '../modals/SMSModal';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import Moment from 'react-moment';
import classnames from 'classnames';

const SendLinks = ({
  sendSMS,
  adminCreateContact,
  adminLoadContacts,
  adminDeleteContacts,
  contacts,
}) => {
  useEffect(() => {
    const tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips);
    M.updateTextFields();
  }, []);

  useEffect(() => {
    adminLoadContacts();
  }, [adminLoadContacts]);

  const openSMSModal = () => {
    if (selectedItems.length > 0) {
      const elem = document.getElementById('smsModal');
      const modal = M.Modal.init(elem);
      modal.open();
    } else {
      M.toast({
        html: 'Please select at least one contact before doing that.',
      });
    }
  };

  // ADD NEW CONTACT
  const [showAddFields, setShowAddFields] = useState(false);
  const [newContactInfo, setNewContactInfo] = useState({
    name: '',
    childname: '',
    phone: '',
    redcapid: '',
  });
  const { name, childname, phone, redcapid } = newContactInfo;
  const onContactChange = (e) => {
    setNewContactInfo({ ...newContactInfo, [e.target.name]: e.target.value });
  };
  const submitNewContact = (e) => {
    e.preventDefault();
    adminCreateContact(newContactInfo, setNewContactInfo);
  };

  // SELECT CONTACTS
  const [selectedItems, setSelectedItems] = useState([]);
  const toggleContact = (contact, phone) => {
    if (selectedItems.filter((item) => item.id === contact).length) {
      setSelectedItems([
        ...selectedItems.filter((item) => item.id !== contact),
      ]);
    } else {
      setSelectedItems([...selectedItems, { id: contact, phone }]);
    }
  };
  const clearSelected = () => {
    setSelectedItems([]);
    const checks = document.querySelectorAll('input[type=checkbox]');
    checks.forEach((check) => (check.checked = false));
  };

  // DELETE CONTACTS
  const deleteContacts = (e) => {
    e.preventDefault();
    if (selectedItems.length > 0) {
      if (
        window.confirm('Are you sure you want to delete the selected contacts?')
      ) {
        adminDeleteContacts(selectedItems, clearSelected);
      }
    } else {
      M.toast({
        html: 'Please select at least one contact before doing that.',
      });
    }
  };

  return (
    <Fragment>
      <div className='row'>
        <div className='col s12'>
          <h3>
            <Link
              to='/dashboard'
              className='tooltipped'
              data-tooltip='Back to Dashboard'
            >
              <i className='material-icons small'>arrow_back</i>
            </Link>{' '}
            Send Links
          </h3>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='card'>
            <div className='card-content'>
              <p>Manage your contact list to send invitation links by SMS.</p>
            </div>
            <div className='card-content grey lighten-4'>
              <nav>
                <div className='nav-wrapper secondary-color'>
                  <span className='ml-3'>Contacts</span>
                  <ul className='right'>
                    <li
                      className={classnames({
                        active: showAddFields,
                      })}
                    >
                      <a
                        href='#!'
                        className='tooltipped'
                        data-tooltip='Add contacts'
                        onClick={(e) => setShowAddFields(!showAddFields)}
                      >
                        <i className='material-icons'>group_add</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href='#!'
                        className={classnames('tooltipped', {
                          disabled: selectedItems.length === 0,
                        })}
                        onClick={(e) => deleteContacts(e)}
                        data-tooltip='Remove contacts'
                      >
                        <i className='material-icons'>delete</i>
                      </a>
                    </li>
                    <li>
                      <a
                        href='#!'
                        className={classnames('tooltipped', {
                          disabled: selectedItems.length === 0,
                        })}
                        data-tooltip='Send SMS'
                        onClick={(e) => openSMSModal(e)}
                      >
                        <i className='material-icons'>send</i>
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
              {showAddFields && (
                <div className='card-panel'>
                  <form onSubmit={(e) => submitNewContact(e)}>
                    <div className='row mb-0'>
                      <div className='input-field col s3'>
                        <input
                          id='name'
                          name='name'
                          value={name}
                          type='text'
                          onChange={(e) => onContactChange(e)}
                          required
                        />
                        <label htmlFor='name'>Parent Name *</label>
                      </div>
                      <div className='input-field col s3'>
                        <input
                          id='childname'
                          name='childname'
                          value={childname}
                          onChange={(e) => onContactChange(e)}
                          type='text'
                        />
                        <label htmlFor='childname'>Child Name</label>
                      </div>
                      <div className='input-field col s3'>
                        <input
                          id='phone'
                          name='phone'
                          value={phone}
                          type='text'
                          onChange={(e) => onContactChange(e)}
                          required
                          minLength='12'
                        />
                        <label htmlFor='phone'>Mobile Phone *</label>
                        <span className='helper-text'>
                          International format
                        </span>
                      </div>
                      <div className='input-field col s3'>
                        <input
                          id='redcapid'
                          name='redcapid'
                          value={redcapid}
                          onChange={(e) => onContactChange(e)}
                          type='text'
                        />
                        <label htmlFor='redcapid'>Redcap ID</label>
                      </div>
                      <div className='col s3 right-align'>
                        <br />
                        <button type='submit' className='btn'>
                          Add Contact
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
              <table>
                <thead>
                  <tr>
                    <th />
                    <th>Parent Name</th>
                    <th>Child Name</th>
                    <th>Phone Number</th>
                    <th>Link</th>
                    <th>Sent</th>
                    <th>Status</th>
                  </tr>
                </thead>

                <tbody>
                  {contacts.map((contact) => (
                    <tr key={contact._id}>
                      <td>
                        <label>
                          <input
                            type='checkbox'
                            className='filled-in'
                            value={contact._id}
                            onChange={(e) =>
                              toggleContact(e.target.value, contact.phone)
                            }
                          />
                          <span />
                        </label>
                      </td>
                      <td>{contact.name}</td>
                      <td>{contact.childname}</td>
                      <td>
                        {contact.phone.replace(
                          /^(\+?[\d]{2})\d+(\d{3})$/g,
                          '$1*****$2'
                        )}
                      </td>
                      <td>{`https://${window.location.host}/${contact._id}`}</td>
                      <td>
                        <Moment format='MM/DD/YYYY'>{contact.date}</Moment>
                      </td>
                      {contact.active > 0 &&
                        (new Date() - new Date(contact.date)) /
                          (1000 * 60 * 60 * 24) <
                          7 && <td>Link sent</td>}
                      {contact.active > 0 &&
                        (new Date() - new Date(contact.date)) /
                          (1000 * 60 * 60 * 24) >=
                          10 && <td style={{ color: 'red' }}>Expired</td>}
                      {contact.active <= 0 && (
                        <td style={{ color: 'green' }}>Completed</td>
                      )}
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th />
                    <th>Parent Name</th>
                    <th>Child Name</th>
                    <th>Phone Number</th>
                    <th>Sent</th>
                    <th>Status</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      <SMSModal contacts={selectedItems} clearSelected={clearSelected} />
    </Fragment>
  );
};

SendLinks.propTypes = {
  sendSMS: PropTypes.func.isRequired,
  adminCreateContact: PropTypes.func.isRequired,
  adminLoadContacts: PropTypes.func.isRequired,
  adminDeleteContacts: PropTypes.func.isRequired,
  contacts: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  contacts: state.dashboard.contacts,
});

export default connect(mapStateToProps, {
  sendSMS,
  adminCreateContact,
  adminLoadContacts,
  adminDeleteContacts,
})(SendLinks);
