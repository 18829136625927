import React, { Fragment, useEffect, useState } from "react";
import M from "materialize-css";
import { Link } from "react-router-dom";
import CreateConcernForm from "./CreateConcernForm";
import { adminSaveConcern } from "../../actions/dashboard";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../layout/Spinner";

const CreateConcern = ({ adminSaveConcern, history, loading, saving }) => {
  const [formData, setFormData] = useState({
    name: "",
    name_es: "",
    content: "",
    content_es: "",
    suggestedquestions: [],
    suggestedquestions_es: [],
    externallinks: [],
    videolink: "",
  });

  useEffect(() => {
    const tooltips = document.querySelectorAll(".tooltipped");
    M.Tooltip.init(tooltips);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    adminSaveConcern(formData, history);
  };

  return (
    <Fragment>
      {loading || saving ? (
        <Spinner />
      ) : (
        <Fragment>
          Svaing: {saving}
          <div className="row">
            <div className="col s12">
              <h3>
                <Link
                  to="/dashboard/concerns"
                  className="tooltipped"
                  data-tooltip="Back to Concerns"
                >
                  <i className="material-icons small">arrow_back</i>
                </Link>{" "}
                Create Concern
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col s12">
              <div className="card">
                <form onSubmit={(e) => onSubmit(e)}>
                  <div className="card-content grey lighten-4">
                    <CreateConcernForm
                      formData={formData}
                      setFormData={setFormData}
                    />
                  </div>
                  <div className="card-action">
                    <button type="submit" className="btn waves-effect">
                      <i className="material-icons left">playlist_add</i> Create
                      concern
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

CreateConcern.propTypes = {
  adminSaveConcern: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  saving: state.concerns.saving,
});

export default connect(mapStateToProps, {
  adminSaveConcern,
})(CreateConcern);
