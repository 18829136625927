import React, { useEffect, useState } from 'react';
import { sendFeedback } from '../../actions/feedback';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import M from 'materialize-css';

import translate from '../../locales/translate.js';

const FeedbackModal = ({ sendFeedback }) => {
  useEffect(() => {
    const modals = document.querySelectorAll('.modal');
    M.Modal.init(modals, {
      onOpenEnd: e => setFormData({...clearFormData})
    });
  }, []);

  const [formData, setFormData] = useState({
    type: 'website',
    useful: false,
    findinformation: false,
    wouldrecommend: false,
    addorimprove: '',
  });

  const clearFormData = formData;

  const { useful, findinformation, wouldrecommend, addorimprove } = formData;

  const onChange = e =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });

  const onCbox = e =>
    setFormData({
      ...formData,
      [e.target.name]: e.target.checked
    });

  return (
    <div id='feedbackModal' className='modal center'>
      <div className='modal-content'>
        <h4>{translate('feedback.header')}</h4>
        <p>{translate('feedback.question1')}</p>
        <div className="switch ">
          <label>
            {translate('feedback.no')}
            <input type="checkbox" name="useful" checked={useful} onChange={e => onCbox(e)} />
            <span className="lever"></span>
            {translate('feedback.yes')}
          </label>
        </div>
        
        <p>{translate('feedback.question2')}</p>
        <div className="switch ">
          <label>
            {translate('feedback.no')}
            <input type="checkbox" name="findinformation" checked={findinformation} onChange={e => onCbox(e)} />
            <span className="lever"></span>
            {translate('feedback.yes')}
          </label>
        </div>
        
        <p>{translate('feedback.question3')}</p>
        <div className="switch ">
          <label>
            {translate('feedback.no')}
            <input type="checkbox" name="wouldrecommend" checked={wouldrecommend} onChange={e => onCbox(e)} />
            <span className="lever"></span>
            {translate('feedback.yes')}
          </label>
        </div>
        
        <p>{translate('feedback.question4')}</p>
        <div className="row">
          <div className="input-field col s8 offset-s2">
            <textarea id="addorimprove" name="addorimprove" value={addorimprove} onChange={e => onChange(e)} className="materialize-textarea" data-length="120"></textarea>
            <label htmlFor="addorimprove"></label>
          </div>
        </div>
        
        <button className="btn waves-effect waves-light" type="button" onClick={e => sendFeedback(formData)}>{translate('submit')}
          <i className="material-icons right">send</i>
        </button>
      </div>
    </div>
  );
};

FeedbackModal.propTypes = {
  sendFeedback: PropTypes.func.isRequired
};

export default connect(
  null,
  {
    sendFeedback
  }
)(FeedbackModal);
