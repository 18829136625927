import { GET_QUESTION, QUESTION_ERROR, CLEAR_QUESTION } from '../actions/types';

const initialState = {
  question: {},
  error: {},
  loading: true
};

export default function(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_QUESTION:
      return {
        ...state,
        question: payload,
        loading: false
      };
    case CLEAR_QUESTION:
      return {
        ...state,
        question: {},
        loading: true
      };
    case QUESTION_ERROR:
      return {
        ...state,
        error: payload,
        loading: false
      };
    default:
      return state;
  }
}
