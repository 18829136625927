import React, { Fragment, useEffect, useState } from 'react';
import M from 'materialize-css';
import { Link } from 'react-router-dom';
import CreateQuestionForm from './CreateQuestionForm';
import { adminSaveQuestion } from '../../actions/dashboard';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const CreateQuestion = ({ adminSaveQuestion, history }) => {
  const [formData, setFormData] = useState({
    text: '',
    text_es: '',
    concern: '',
    description: '',
    labelA: '',
    labelB: '',
    labelC: '',
    labelD: '',
    labelE: '',
    labelA_es: '',
    labelB_es: '',
    labelC_es: '',
    labelD_es: '',
    labelE_es: '',
  });

  useEffect(() => {
    const tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    adminSaveQuestion(formData, history);
  };

  return (
    <Fragment>
      <div className='row'>
        <div className='col s12'>
          <h3>
            <Link
              to='/dashboard/questions'
              className='tooltipped'
              data-tooltip='Back to Questions'
            >
              <i className='material-icons small'>arrow_back</i>
            </Link>{' '}
            Create Question
          </h3>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='card'>
            <form onSubmit={(e) => onSubmit(e)}>
              <div className='card-content grey lighten-4'>
                <CreateQuestionForm
                  formData={formData}
                  setFormData={setFormData}
                />
              </div>
              <div className='card-action'>
                <button type='submit' className='btn waves-effect'>
                  <i className='material-icons left'>playlist_add</i> Create
                  question
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

CreateQuestion.propTypes = {
  adminSaveQuestion: PropTypes.func.isRequired,
};

export default connect(null, {
  adminSaveQuestion,
})(CreateQuestion);
