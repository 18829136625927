import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { adminLoadUsers, adminDeleteUser } from '../../actions/dashboard';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import M from 'materialize-css';
import arrayMove from 'array-move';
import classnames from 'classnames';

const ListUsers = ({ users, adminLoadUsers, adminDeleteUser }) => {
  useEffect(() => {
    adminLoadUsers();
  }, [adminLoadUsers]);

  useEffect(() => {
    const tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips, {
      enterDelay: 500
    });
  }, [users]);

  const deleteUser = (e, id) => {
    e.preventDefault();
    if (window.confirm('Are you sure you want to DELETE this user?')) {
      adminDeleteUser(id);
    }
  };

  return (
    <Fragment>
      <div className='row'>
        <div className='col s12'>
          <h3>
            <Link
              to='/dashboard'
              className='tooltipped'
              data-tooltip='Back to Dashboard'
            >
              <i className='material-icons small'>arrow_back</i>
            </Link>{' '}
            Manage Users
          </h3>
        </div>
      </div>
      <div className='row'>
        <div className='col s12'>
          <div className='card'>
            <div className='card-content'>
              <p>Manage who can access the admin dashboard.</p>
            </div>
            <div className='card-content grey lighten-4'>
              <ul className='collection with-header'>
                {users.map((user, key) => {
                  return (
                    <li key={user._id} className='collection-item'>
                      <div className='user-actions'>
                        <span className='user-text'>{user.name}</span>
                        <Link
                          to='#!'
                          onClick={e => {
                            deleteUser(e, user._id);
                          }}
                          className='secondary-content ml-3 tooltipped'
                          data-tooltip='DELETE user'
                        >
                          <i className='material-icons'>delete</i>
                        </Link>
                        <Link
                          to={`/dashboard/users/edit/${user._id}`}
                          className='secondary-content ml-3 tooltipped'
                          data-tooltip='Edit user'
                        >
                          <i className='material-icons'>edit</i>
                        </Link>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='fixed-action-btn'>
        <Link
          to='/dashboard/users/new'
          className='waves-effect btn-floating btn-large red tooltipped z-depth-3'
          data-tooltip='New user'
          data-position='left'
        >
          <i className='large material-icons'>add</i>
        </Link>
      </div>
    </Fragment>
  );
};

ListUsers.propTypes = {
  adminLoadUsers: PropTypes.func.isRequired,
  adminDeleteUser: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  users: state.dashboard.users
});

export default connect(
  mapStateToProps,
  {
    adminLoadUsers,
    adminDeleteUser
  }
)(ListUsers);
