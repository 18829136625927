import React from "react";
import {Route, Switch} from "react-router-dom";
import Login from "../auth/Login";
import Alert from "../layout/Alert";
import Quiz from "../quiz/Quiz";
import NotFound from "../layout/NotFound";
import PrivateRoute from "../routing/PrivateRoute";
import QuizRoute from "../routing/QuizRoute";
import Question from "../quiz/Question";
import Rank from "../quiz/Rank";
import Results from "../quiz/Results";
import Concerns from "../concerns/Concerns";

import Dashboard from "../dashboard/Dashboard";
import EditContents from "../dashboard/EditContents";
import ListQuestions from "../dashboard/ListQuestions";
import CreateQuestion from "../dashboard/CreateQuestion";
import EditQuestion from "../dashboard/EditQuestion";
import ListConcerns from "../dashboard/ListConcerns";
import ListUsers from "../dashboard/ListUsers";
import CreateUser from "../dashboard/CreateUser";
import EditUser from "../dashboard/EditUser";
import CreateConcern from "../dashboard/CreateConcern";
import EditConcern from "../dashboard/EditConcern";
import EditChildrenConcern from "../dashboard/EditChildrenConcern";
import SendLinks from "../dashboard/SendLinks";
import Feedbacks from "../dashboard/ListFeedbacks";
import AboutUs from "../layout/AboutUs";
import ContactUs from "../layout/ContactUs";
import Landing from "../layout/Landing";

const Routes = () => {
    return (
        <div className="container main-container">
            <Alert/>
            <Switch>
                <Route exact path="/aboutus" component={AboutUs}/>
                <Route exact path="/contact" component={ContactUs}/>
                <Route exact path="/quiz/" component={Quiz}/>
                <Route exact path="/quiz/:id" component={Quiz}/>
                <QuizRoute exact path="/question/:number" component={Question}/>
                <Route exact path="/rank/:id" component={Rank}/>
                <Route exact path="/results/:id" component={Results}/>
                <Route exact path="/concerns" component={Concerns}/>
                <Route exact path="/concerns/:id" component={Concerns}/>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/results/:id/sms-share" component={Results}/>
                <Route exact path="/results/:id/email-share" component={Results}/>
                <PrivateRoute exact path="/dashboard" component={Dashboard}/>
                <PrivateRoute
                    exact
                    path="/dashboard/contents"
                    component={EditContents}
                />
                <PrivateRoute
                    exact
                    path="/dashboard/questions"
                    component={ListQuestions}
                />
                <PrivateRoute
                    exact
                    path="/dashboard/questions/new"
                    component={CreateQuestion}
                />
                <PrivateRoute
                    exact
                    path="/dashboard/questions/edit/:id"
                    component={EditQuestion}
                />
                <PrivateRoute
                    exact
                    path="/dashboard/concerns"
                    component={ListConcerns}
                />
                <PrivateRoute
                    exact
                    path="/dashboard/concerns/new"
                    component={CreateConcern}
                />
                <PrivateRoute
                    exact
                    path="/dashboard/concerns/edit/:id"
                    component={EditConcern}
                />
                <PrivateRoute
                    exact
                    path="/dashboard/concerns/children/:id"
                    component={EditChildrenConcern}
                />
                <PrivateRoute exact path="/dashboard/users" component={ListUsers}/>
                <PrivateRoute
                    exact
                    path="/dashboard/users/new"
                    component={CreateUser}
                />
                <PrivateRoute
                    exact
                    path="/dashboard/users/edit/:id"
                    component={EditUser}
                />
                <PrivateRoute exact path="/dashboard/feedbacks" component={Feedbacks}/>
                <PrivateRoute exact path="/dashboard/links" component={SendLinks}/>
                <Route path='/:contactId' render={({
                                                              match,
                                                              ...rest
                                                          }) =>
                    <Landing match={match} {...rest} />}/>
                <Route component={NotFound}/>
            </Switch>
        </div>
    );
};

export default Routes;
